/**
 * Function to map and join an object of css rules
 *
 * @param {Object} ruleSet - Object with key for css rules and belonging values.
 * @returns {String} - string containing the joined rules in valid css syntax.
 */
const buildRules = ruleSet => Object.entries(ruleSet)
  .map(([rName, rValue]) => `--${rName}: ${rValue};`)
  .join('');

/**
 * Exported IIFE that adds an empty stylesheed to the document
 * @returns {IIFE} - self invoking function creating empty style sheet on document.
 */
export const createdStyleSheet = (() => {
  const style = document.createElement('style');
  style.appendChild(document.createTextNode(''));

  document.head.appendChild(style);

  return style.sheet;
})();

/**
 * Exported function to create whitecard stylesheet from rule settings
 * @param {Object} ruleSet - either object that'll get converted to style ruleSet
 * @param {String} ruleSet - or string that is correct format already
 * @returns {String} - returns valid css syntax with wild card
 */
export const makeRuleSheet = (ruleSet) => {
  if (typeof ruleSet === 'string') {
    return ruleSet;
  }

  return `* {
    ${buildRules(ruleSet)}
  }`;
};

/**
 * Exported function that returns a Function
 * @param {DOMNode} sheet - empty document style sheet
 * @param {Object|String} rules - ruleset either as ready to use string or object
 */
export const addStylesToSheet = sheet => (rules) => {
  if (!sheet || !rules) return;

  sheet.insertRule(makeRuleSheet(rules), 0);
};

/**
* @type {Appendix} - this.makeRuleSheet should actually merge two different stylesheets
* but apperently is neither the styles service (responsible for delivering those) ready to use
* nor are the AC's to actually implement these solutions.
*/
// Could use:
// const testStyles = `
//  * {
//   --colorInvalid: red;
//   --color: green;
// }
// `;
// return `* {
//   ${testStyles.match(/{([^}]+)}/)[1].trim()}
//   ${buildRules(ruleSet)}
// }`;
