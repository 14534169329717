import styled from 'styled-components';
import { TextFieldBorder } from './textFieldBorder';

/**
 * @namespace
 * @class TextFieldWrapper
 * @classdesc A (styled) `div` component.
 */
export const TextFieldWrapper = styled.div`
  all: initial;
  display: block;
  position: relative;
  margin-bottom: 1em;
  width: 100%;

  &:hover ${TextFieldBorder} {
    ${props => props.isValid === undefined && `
      border-color: ${props.theme.borderColorHover};
    `}
  }
`;
