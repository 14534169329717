import styled from 'styled-components';

/**
 * @namespace
 * @class SelectFieldFrame
 * @classdesc A (styled) `div` component.
 * @param {Object} props                       - The component properties.
 */
export const SelectFieldFrame = styled.div`
  all: initial;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
`;
