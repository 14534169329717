import styled from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_STYLES = {
  hasFocus: `
    top: .75em;
    transform: scale3d(.85, .85, 1);
  `,
};

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_TEXTFIELD_LABEL = {
  hasValue: () => MODIFIER_STYLES.hasFocus,
  hasFocus: ({ theme }) => MODIFIER_STYLES.hasFocus + `
    color: ${theme.labelColorFocus}`,
  isInvalid: ({ theme }) => `
    color: ${theme.labelColorInvalid};`,
};

/**
 * @namespace
 * @class TextFieldLabel
 * @param {Object} props                       - The component properties.
 * @classdesc A (styled) `label` component.
 */
export const TextFieldLabel = styled.label`
  all: initial;
  font-family: ${props => props.theme.fontFamily};
  position: absolute;
  top: calc(1.3125em + ${props => props.theme.borderWidth});
  left: calc(${props => props.theme.paddingVertical} + ${props => props.theme.borderWidth});
  margin-right: ${props => props.theme.paddingVertical};
  font-size: .875em;
  line-height: 1em;
  color: ${props => props.theme.labelColor};
  transform-origin: 0 0;
  transform: scale3d(1, 1, 1);
  transition:
    transform ${props => props.theme.animationDuration} ease-out,
    top ${props => props.theme.animationDuration} ease-out;
  pointer-events: none;
  z-index: 1; /* at top of browser's autosuggest */

  ${applyStyleModifiers(MODIFIER_TEXTFIELD_LABEL)}
`;

TextFieldLabel.propTypes = {
  /**
   * Modifiers
   */
  modifiers: styleModifierPropTypes(MODIFIER_TEXTFIELD_LABEL),
};
