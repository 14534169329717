export const VIEWPORT_XXS = '(min-width: 0px) and (max-width: 479px)';
export const VIEWPORT_XXS_MIN = '(min-width: 0px)';
export const VIEWPORT_XXS_MAX = '(max-width: 479px)';
export const VIEWPORT_XS = '(min-width: 480px) and (max-width: 767px)';
export const VIEWPORT_XS_MIN = '(min-width: 480px)';
export const VIEWPORT_XS_MAX = '(max-width: 767px)';
export const VIEWPORT_SM = '(min-width: 768px) and (max-width: 991px)';
export const VIEWPORT_SM_MIN = '(min-width: 768px)';
export const VIEWPORT_SM_MAX = '(max-width: 991px)';
export const VIEWPORT_MD = '(min-width: 992px) and (max-width: 1199px)';
export const VIEWPORT_MD_MIN = '(min-width: 992px)';
export const VIEWPORT_MD_MAX = '(max-width: 1199px)';
export const VIEWPORT_LG = '(min-width: 1200px)';
export const VIEWPORT_LG_MIN = '(min-width: 1200px)';
