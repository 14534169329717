import React from 'react';
import PropTypes from 'prop-types';

import StyledFieldError from './FieldError.style';

const FieldError = props => (
  <StyledFieldError htmlFor={props.htmlFor}>{props.children}</StyledFieldError>
);

FieldError.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default FieldError;
