// TODO implement in UI-Kit

export const UNIT_SPACE_CHAR = '\u202F';
export const UNIT_SPACE_WORD = '\u00A0';

/**
 * Returns a number formatted as amount with optional unit
 * WARNING: This is not safe for international use
 *
 * @param {Number} amount
 * @param {String} [unit]
 * @param {Number} [decimalDigits]
 */
export const formatAmount = (amount, unit, decimalDigits = 0) => {
  const rxThousands = /(\d+)(\d{3})/;

  const [digits, decimals] = (amount || 0).toFixed(decimalDigits).split('.');

  // insert thousands separators
  // TODO: locale dependent format
  let value = digits;
  while (rxThousands.test(value)) {
    value = value.replace(rxThousands, '$1.$2');
  }
  if (decimals) value += ',' + decimals;

  if (!unit) return value;

  const unitSpace = unit.length > 1 ? UNIT_SPACE_WORD : UNIT_SPACE_CHAR;
  return value + unitSpace + unit;
};

/**
 * Will strip thousands separator off the value and convert it to a regular float
 * This is required when reading the field
 * WARNING: This is not safe for international use
 *
 * @param {string} amount - the amount that gets turned into float
 * @returns {Number} - float
 */
export const parseAmount = amount => (
  parseFloat((amount + '').replace(/[^,\d]/g, '').replace(',', '.')) || 0
);
