
function randomString(length = 26, charSet = 'abcdef0123456789') {
  let string = '';
  for (let i = 0; i < length; i += 1) {
    const randomPoz = Math.floor(Math.random() * charSet.length);
    string += charSet.substring(randomPoz, randomPoz + 1);
  }
  return string;
}

export const getSessionStorage = (params) => {
  if (typeof sessionStorage !== 'undefined') {
    const ffgStorage = JSON.parse(sessionStorage.getItem('ffg')) || {};

    if (!params) {
      return ffgStorage;
    }

    if (typeof params !== 'object') {
      console.error('sessionStorage needs to be an object!');
      return false;
    }

    const ffgStorageUpdated = { ...ffgStorage, ...params };
    try {
      sessionStorage.setItem('ffg', JSON.stringify(ffgStorageUpdated));
    }
    catch (e) {
      // ignore
    }
    return ffgStorageUpdated;
  }
  return false;
};

export const setSessionStorage = (key, value) => {
  getSessionStorage({
    [key]: value,
  });
};

export default function () {
  if (typeof window !== 'undefined') {
    const { bid } = getSessionStorage();

    if (!bid) {
      setSessionStorage('bid', randomString(32));
    }
  }
}
