import React from 'react';
import TextField from '../TextField';
import { TextFieldPropTypes, TextFieldDefaultProps } from '../TextField/PropTypes';


/**
 * Will clean up a phone number
 *
 * @param {string} phone
 * @returns {string}
 */
export const cleanValue = phone => (
  phone.trim()
    .replace(/^00/, '+') // convert 0049 to +49 (Din 5008 int)
    .replace(/[()-]/g, '') // strip brackets & dashes
    .replace(/\s\s+/g, ' ') // collapse whitespaces
);


class PhoneField extends React.PureComponent {

  static propTypes = TextFieldPropTypes;

  static defaultProps = TextFieldDefaultProps;

  /**
   * Adds custom validity messages directly to the input.
   *
   * @param {HTMLInputElement} input
   */
  updateCustomValidity = (input) => {
    const validityState = input.validity;

    // respect already set errors
    if (validityState.customError) return;

    let error = '';

    if (validityState.patternMismatch) {
      error = 'Ihre Eingabe muss eine gültige Telefonnummer sein.';
    }
    else if (validityState.tooShort) {
      error = 'Ihre Eingabe ist zu kurz für eine Telefonummer.';
    }

    input.setCustomValidity(error);
  };

  /**
   * Handles input event on input
   *
   * @param {Event} event
   */
  handleInput = (event) => {
    const { target } = event;

    this.updateCustomValidity(target);

    this.props.onInput(event, cleanValue(target.value));
  };

  /**
   * Handles change event on input
   *
   * @param {Event} event
   */
  handleChange = (event) => {
    const { target } = event;

    this.props.onChange(event, cleanValue(target.value));
  };

  /**
   * @returns {JSX}
   */
  render() {
    return (
      <TextField
        {...this.props}
        type="tel"
        pattern="(\\+|00)?[0-9][0-9 -]*[0-9]"
        maxLength={15}
        minLength={8}
        onInput={this.handleInput}
        onChange={this.handleChange}
      />
    );
  }

}

export default PhoneField;
