import { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';


/**
 * Creates a container at the end of the document body where modals can be registered
 */
export default class ModalStack extends PureComponent {

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  static defaultProps = {
    children: undefined,
  };

  constructor(props) {
    super(props);

    this.mountPoint = null;
  }

  componentWillMount() {
    // use existing DIV container or create new one
    this.mountPoint = this.mountPoint || document.createElement('div');
    this.mountPoint.id = 'ffg-modalStack'; // This is referenced in Modal.js

    if (this.props.children) {
      document.body.appendChild(this.mountPoint);
    }
  }

  componentDidMount() {
    ReactDOM.render(this.props.children, this.mountPoint);
  }

  /**
   *  If mount point
   *  - will contain children and
   *  - is not already in the DOM
   * add it to the end of the document body.
   *
   * @param {Object} nextProps
   */
  componentWillUpdate(nextProps) {
    if (!this.mountPoint.parentElement && nextProps.children) {
      document.body.appendChild(this.mountPoint);
    }
  }

  componentDidUpdate() {
    ReactDOM.render(this.props.children, this.mountPoint);
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.mountPoint);
    document.body.removeChild(this.mountPoint);
  }

  render() {
    return null;
  }

}
