import styled from 'styled-components';

/**
 * @namespace
 * @class CheckboxFieldLabel
 * @param {Object} props                       - The component properties.
 * @classdesc A (styled) `label` component.
 */
export const CheckboxFieldDescription = styled.label`
  all: initial;
  display:block;
  padding-top: .125em;
  font-family: ${props => props.theme.fontFamily};
  font-size: .875em;
  line-height: 1.25em;
  color: ${props => props.theme.labelColor};
`;
