import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { getTheme } from './themeHelper';

/**
 * @namespace
 * @class ThemeHoc
 * @classdesc A higher order component which generates a theme with custom properties from a CSS
 * file, pass it down to the children and show them _after_ the theme generation has finished.
 * As long the theme generation is going on a SVG `IconSpinner` animation showing a spinner, else showing
 * the passed `children`.
 * @param {Object} properties                     - The component properties.
 * @property {*} [children={}]                    - Node child components.
 * @property {*} [affiliateCSSUrl]                - The affiliate CSS URL.
 * @property {boolean} [enabled=true]             - Whether the theme is to be enabled in the app or not.
 * @property {boolean} [enableGlobalStyles=false] - Whether the global styles are enabled in the app or not.
 * @returns {JSX} The component.
 */
export default class ThemeHoc extends Component {

  /**
   * The constructor
   *
   * @param {Object} props - The props.
   */
  constructor(props) {
    super(props);
    /**
     * The internal component state object.
     * @type {Object}
     * @private
     */
    this.state = {
      theme: false,
    };
  }

  /**
   * Sets the theme as state.
   */
  async componentDidMount() {
    const {
      affiliateCSSUrl,
      enableGlobalStyles,
    } = this.props;

    // @TODO find a solution for:
    // Warning: Can only update a mounted or mounting component. This usually means you called setState,
    // replaceState, or forceUpdate on an unmounted component. This is a no-op.
    // Please check the code for the ThemeHoc component.
    // https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
    const theme = await getTheme(affiliateCSSUrl, window, document, enableGlobalStyles);
    this.setState({ theme });
  }

  /**
   * Renders the component.
   *
   * @returns {JSX} - The rendered wrapper component.
   */
  render() {
    const {
      children,
      enabled,
    } = this.props;

    // This is useful if the parent app already uses a theme!
    if (!enabled) {
      return children;
    }

    const { theme } = this.state;

    return theme
      ? <ThemeProvider theme={theme}>{children}</ThemeProvider>
      : null;
  }

}

/**
 * The property types definition.
 * @type {Object}
 * @memberof ThemeHoc
 * @private
 */
ThemeHoc.propTypes = {
  /**
   * Node child components.
   */
  children: PropTypes.node.isRequired,
  /**
   * The affiliate CSS URL.
   */
  affiliateCSSUrl: PropTypes.string,
  /**
   * Whether the theme is to be disabled in the app (default: `true`).
   */
  enabled: PropTypes.bool,
  /**
   * Whether the global styles are enabled in the app (default: `false`).
   */
  enableGlobalStyles: PropTypes.bool,
};

/**
 * The default property types definition.
 * @type {Object}
 * @memberof ThemeHoc
 * @private
 */
ThemeHoc.defaultProps = {
  enabled: true,
  enableGlobalStyles: false,
  affiliateCSSUrl: null,
};
