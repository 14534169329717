const calculateMonthlyInterest = i => ((1 + i) ** (1 / 12)) - 1;
const calculateEquivalentAnnualCost = (i, n) => (((1 + i) ** n) * i) / (((1 + i) ** n) - 1);

/**
 * Returns the total amount a person has to pay for a credit
 *
 * @param {{ amount: Number, effectiveInterest: Number, term: Number}}
 * @returns {Number}
 */
export const calculateTotal = ({ amount, effectiveInterest, term }) => {
  const paymentsPerYear = 12;
  const effectiveInterestPerInstallment = effectiveInterest / paymentsPerYear;

  if (effectiveInterestPerInstallment === 0) {
    return amount;
  }

  return (
    amount * effectiveInterestPerInstallment * (
      ((1 + effectiveInterestPerInstallment) ** term) /
      (((1 + effectiveInterestPerInstallment) ** term) - 1)
    ) * term
  );
};

/**
 * Returns the monthly payment for a credit based on parameters
 *
 * @param {{ amount: Number, effectiveInterest: Number, term: Number}}
 * @returns {Number}
 */
export const calculateMonthlyPayment = ({ amount, effectiveInterest, term }) => {
  // calculate monthly payment
  const monthlyPayment = amount * calculateEquivalentAnnualCost(calculateMonthlyInterest(effectiveInterest), term);

  // round to two decimal places
  return Math.round(100 * monthlyPayment) / 100;
};

/**
 * Returns amount of possible credit based on parameters
 * effectiveInterestRate as Float aka 0,69% -> 0.69
 *
 * @param {{ rate: Number, effectiveInterestRate: Number, term: Number}}
 * @returns {Number}
 */
export const calculateCreditAmount = ({ rate, effectiveInterestRate, term }) => {
  const maf = ((effectiveInterestRate / 100) + 1) ** (1 / 12);
  const amount = (rate / (maf - 1)) * (((maf ** term) - 1) / (maf ** term));
  return Math.round(amount);
};
