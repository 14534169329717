import styled from 'styled-components';

/**
 * @namespace
 * @class TextFieldFrame
 * @param {Object} props                       - The component properties.
 * @classdesc A (styled) `div` component.
 */
export const TextFieldFrame = styled.div`
  all: initial;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  background-color: ${props => props.theme.backgroundColor};
`;
