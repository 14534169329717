import styled from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_TEXTFIELD_HINT = {
  isInvalid: () => 'display: none;',
};

/**
 * @namespace
 * @class TextFieldHint
 * @param {Object} props                       - The component properties.
 * @classdesc A (styled) `label` component.
 */
export const TextFieldHint = styled.label`
  all: initial;
  display: block;
  margin: .5em ${props => props.theme.paddingVertical};
  font-size: .75em;
  line-height: 1em;
  color: ${props => props.theme.hintColor};
  pointer-events: none;

  ${applyStyleModifiers(MODIFIER_TEXTFIELD_HINT)}
`;

TextFieldHint.propTypes = {
  /**
   * Modifiers
   */
  modifiers: styleModifierPropTypes(MODIFIER_TEXTFIELD_HINT),
};
