import React from 'react';
import PropTypes from 'prop-types';

import StyledFieldError from './FieldHint.style';

const FieldHint = props => (
  <StyledFieldError htmlFor={props.htmlFor}>{props.children}</StyledFieldError>
);

FieldHint.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default FieldHint;
