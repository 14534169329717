import styled from 'styled-components';
import FieldError from '../../../atoms/FieldError/FieldError.style';
import FieldHint from '../../../atoms/FieldHint/FieldHint.style';

/**
 * @namespace
 * @class SelectFieldWrapper
 * @classdesc A (styled) `div` component.
 */
export const SelectFieldWrapper = styled.div`
  all: initial;
  display: block;
  position: relative;
  margin-bottom: 1em;
  width: 100%;
  ${FieldError}, ${FieldHint}  {
    margin-left: ${props => props.theme.paddingVertical};
    margin-right: ${props => props.theme.paddingVertical};
  }
`;
