import Styled from 'styled-components';

export const App = Styled.div`
  all: initial;
  font-family: 'Lato', sans-serif;
  color: ${props => props.theme.color};

  * {
    box-sizing: border-box;
  }

  /* TODO move to own atom */
  a {
    color: ${props => props.theme.linkColor} !important;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
    color: ${props => props.theme.linkColorHover};
  }
  a:active {
    color: ${props => props.theme.linkColorActive};
  }
`;

export const LoadingOverlay = Styled.div`
    background: #ffffff;
    opacity: 0.9;
    color: red;
    text-align: center;
    font-weight: bold;
    position: absolute;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;
