import React from 'react';

import FieldHint from '../../../atoms/FieldHint/FieldHint';
import FieldError from '../../../atoms/FieldError/FieldError';

import { SelectFieldWrapper } from './selectFieldWrapper';
import { SelectFieldInput } from './selectFieldInput';
import { SelectFieldFrame } from './selectFieldFrame';
import { SelectFieldLabel } from './selectFieldLabel';
import { SelectFieldIconDropdown } from './selectFieldIconDropdown';
import { TextFieldIconValid } from '../../TextField/ffg/textFieldIconValid';

import { SelectFieldDefaultProps, SelectFieldPropTypes } from '../PropTypes';


class SelectField extends React.PureComponent {

  static propTypes = SelectFieldPropTypes;

  static defaultProps = SelectFieldDefaultProps;

  render() {
    const { hasFocus, isValid, isRequired } = this.props;
    return (
      <SelectFieldWrapper>
        <SelectFieldFrame>
          <SelectFieldInput
            id={this.props.id}
            ref={this.props.inputRef}
            name={this.props.name}
            required={this.props.isRequired}
            disabled={this.props.isDisabled}
            onFocus={this.props.onFocus}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            onInvalid={this.props.onInvalid}
            modifiers={[
              ...(hasFocus ? ['hasFocus'] : []),
              ...(isValid === false ? ['isInvalid'] : []),
              ...(isValid === true ? ['isValid'] : []),
              ...(hasFocus && isValid === false ? ['isInvalidAndHasFocus'] : []),
              ...(isRequired && hasFocus && isValid === true ? ['isRequiredAndisValidAndHasFocus'] : []),
            ]}
          >
            {this.props.options.map((option, idx) => {
              const optionId = this.props.id + '-' + idx;

              return (
                <option key={optionId} value={option.value} selected={this.props.value === option.value}>{option.label}</option>
              );
            })}
          </SelectFieldInput>

          <SelectFieldIconDropdown {...{ hasFocus, isValid }} />
          <TextFieldIconValid {...{ hasFocus, isValid }} />
          <SelectFieldLabel
            htmlFor={this.props.id}
            modifiers={[
              ...(this.props.hasFocus ? ['hasFocus'] : []),
              ...(this.props.isValid === false ? ['isInvalid'] : []),
            ]}
          >
            {this.props.label}
          </SelectFieldLabel>
        </SelectFieldFrame>
        {this.props.error && (
          <FieldError htmlFor={this.props.id}>{this.props.error}</FieldError>
        )}
        {this.props.hint && (
          <FieldHint htmlFor={this.props.id}>{this.props.hint}</FieldHint>
        )}
      </SelectFieldWrapper>
    );
  }

}

export default SelectField;
