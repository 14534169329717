const WHITELIST_GENERIC_PARAMS = [
  'term',
  'amount',
  'purpose',
];


// data parameters which are used by the widgets
const DATA_ATTRIBUTE_NAMES = [
  'advertisementId',
  'amount',
  'amp',
  'buttonText',
  'headlineText',
  'containerUrl',
  'debtors',
  'email',
  'firstName',
  'firstName2',
  'hero',
  'id',
  'lastName',
  'lastName2',
  'layout',
  'noLogo',
  'phone',
  'productId',
  'purpose',
  'rate',
  'relation',
  'salutation',
  'salutation2',
  'subId',
  'submitMode',
  'targetUrl',
  'term',
];

// data parameters which should be parsed to Number type
const DATA_ATTRIBUTE_AS_NUMBER = [
  'amount',
  'term',
  'rate',
];

const DATA_ATTRIBUTE_UPPERCASE = [
  'purpose',
];

const DATA_ATTRIBUTE_BOOLEAN = [
  'bodyShadow',
];

/**
 * Function to replace hyphens
 * @param {String} string - string on which replacements should be performed
 * @returns {String} - string without any hyphens and camelCased instead
 */
const makeCamelCase = string => string.replace(/-([a-z])/gi, (s, $1) => $1.toUpperCase());

/**
 * Create objects from <script data-xx /> data attributes.
 * Attributes on the fixed list above are used to prefill properties on the widgets.
 * Attributes prefixed with data-param- are interpreted as pass-through parameters for CAFE.
 * Everything else will be used as style-parameters.
 * @param {Object} {attributes} - html element of type script tag
 * @returns {Object} stylesObject - object of shape {key<styles_name>: value<css_valid_value>}
 */
export const extractAffiliateData = (attributes) => {
  const affiliateData = {};
  const styles = {};
  const parameters = {};

  Object
    .entries(attributes)
    .forEach(([name, value]) => {
      if (!name.startsWith('data-') && !WHITELIST_GENERIC_PARAMS.includes(name)) return;

      // check if generic pass through parameter
      const isParam = name.startsWith('data-param-');

      // build camel cased attribute name
      const attributeName = makeCamelCase(name.replace(isParam ? 'data-param-' : 'data-', ''));

      // choose target object
      let attributeObject = styles;
      if (isParam) attributeObject = parameters;
      else if (DATA_ATTRIBUTE_NAMES.includes(attributeName)) attributeObject = affiliateData;

      // Unpack value if multiple values have been picked up (same URL parameter multiple times)
      const isArray = Array.isArray(value);
      let saveValue = value;
      if (isArray) {
        saveValue = value.pop();
      }

      // adapt value to requirements
      const isNumber = DATA_ATTRIBUTE_AS_NUMBER.includes(attributeName);
      const shouldBeUppercase = DATA_ATTRIBUTE_UPPERCASE.includes(attributeName);
      const isBoolean = DATA_ATTRIBUTE_BOOLEAN.includes(attributeName);


      let adaptedValue = saveValue;
      if (isNumber) {
        adaptedValue = parseInt(saveValue, 10);
      }
      else if (shouldBeUppercase) {
        adaptedValue = saveValue.toUpperCase();
      }
      else if (isBoolean) {
        adaptedValue = !!saveValue;
      }

      attributeObject[attributeName] = adaptedValue;
    });

  // add parameters to affiliate data if there are any
  if (Object.keys(parameters).length) affiliateData.parameters = parameters;

  // Set submitMode to AMP if data-amp is set to true. This is done by us if the widgets get loaded via /amp
  if (affiliateData.amp) {
    affiliateData.submitMode = 'AMP';
  }

  return { affiliateData, styles };
};
