import React from 'react';
import styled from 'styled-components';

import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';

const IconInvalidComponent = props => (
  <i {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line max-len
        d="M11.5,15 L12.5,15 C12.7761424,15 13,15.2238576 13,15.5 L13,16.5 C13,16.7761424 12.7761424,17 12.5,17 L11.5,17 C11.2238576,17 11,16.7761424 11,16.5 L11,15.5 C11,15.2238576 11.2238576,15 11.5,15 Z M11.5,7 L12.5,7 C12.7761424,7 13,7.22385763 13,7.5 L13,12.5 C13,12.7761424 12.7761424,13 12.5,13 L11.5,13 C11.2238576,13 11,12.7761424 11,12.5 L11,7.5 C11,7.22385763 11.2238576,7 11.5,7 Z M2,12 C2,17.52 6.47,22 11.99,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 11.99,2 C6.47,2 2,6.48 2,12 Z M4,12 C4,7.58 7.58,4 12,4 C16.42,4 20,7.58 20,12 C20,16.42 16.42,20 12,20 C7.58,20 4,16.42 4,12 Z"
        id="path-1"
      />
    </svg>
  </i>
);

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_ICON_CLEAR = {
  hasFocus: ({ theme }) => `
    margin-left: calc(${theme.paddingVertical} * -1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
    visibility: visible;
    transition:
      margin ${theme.animationDuration} ease-out,
      transform ${theme.animationDuration} ${theme.animationDuration} ease-out,
      opacity ${theme.animationDuration} ${theme.animationDuration} ease-out,
      visibility ${theme.animationDuration} ${theme.animationDuration} linear
    ;`,
  isInvalid: ({ theme }) => `color: ${theme.labelColorInvalid};`,
};

/**
 * @namespace
 * @class TextFieldIconInvalidButton
 * @param {Object} props                       - The component properties.
 * @classdesc A custom (styled) `IconInvalidComponent` component.
 */
export const TextFieldIconInvalidButton = styled(IconInvalidComponent)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.theme.iconWidth};
  opacity: 0;
  visibility: hidden; /* shouldn't be clickable while invisible */
  transform: scale3d(.7, .7, 1);
  transition:
    margin ${props => props.theme.animationDuration} ease-out,
    transform ${props => props.theme.animationDuration} ease-out,
    opacity ${props => props.theme.animationDuration} ease-out,
    visibility ${props => props.theme.animationDuration} linear;
  z-index: 1; /* at top of browser's autosuggest */
  ${applyStyleModifiers(MODIFIER_ICON_CLEAR)}
`;

TextFieldIconInvalidButton.propTypes = {
  /**
   * Modifiers
   */
  modifiers: styleModifierPropTypes(MODIFIER_ICON_CLEAR),
};
