import Styled from 'styled-components';
import {
  VIEWPORT_XS_MIN,
} from '../../util/mediaQueries';
import { WIDGET_ROOT_TAG } from '../../Ids';

export const DIRECTION_HORIZONTAL = 'horizontal';
export const DIRECTION_VERTICAL = 'vertical';

// This is the current color-set used in this component
export const theme = {
  backgroundColor: '#FFFFFF',
  backgroundColorChecked: '#165297',
  borderColor: '#B0B9C0',
  borderColorHover: '#4A535A',
  borderColorFocus: '#4575AC',
  borderColorInvalid: '#FF4942',
  borderColorChecked: '#165297',
  radioColor: '#FFFFFF',
  labelColor: '#7B8A96',
  labelColorFocus: '#165297',
  labelColorChecked: '#4A535A',
  labelColorInvalid: '#FF4942',
  shadow: '0 0 0 transparent',
  shadowFocus: '0 0 .375em .0625em #62ABFF',
  shadowInvalid: '0 0 .375em .0625em #FF6262',
};

const borderWidth = '0.0625em';
const animationDuration = '0.3s';

export const Container = Styled.fieldset`
  display: block;
  margin: 0em 0 1em;
  padding: 0;
  border: none;
`;

export const Label = Styled.legend`
  display: block;
  font-size: 0.875em;
  line-height: 1em;
  color: ${props => props.theme.labelColor};
  transform: scale3d(0.85, 0.85, 1);
  transform-origin: 0 0;

  ${props => props.hasFocus && `
    color: ${props.theme.labelColorFocus};
  `}
  ${props => props.isValid === false && `
    color: ${props.theme.labelColorInvalid};
  `};
`;

export const Icon = Styled.image`
  padding:4;
`;

export const Tabs = Styled.div`
  display: flex;
  flex-direction:column;
  flex:1;

  & > div {
    border-top: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
    border-left: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
    border-right: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
    border-bottom: none;
    box-shadow: ${props => props.theme.shadow};
    transition: box-shadow ${props => props.theme.animationDuration} ease-out;
    background: ${props => props.theme.colorBaseLight90};
    &:first-child {
      border-top-left-radius: .125em;
      border-top-right-radius: .125em;
      border-bottom-left-radius: 0;
    }
    &:last-child {
      border-bottom-left-radius: .125em;
      border-bottom-right-radius: .125em;
      border-bottom: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
    }
  }
`;

export const ResponsiveTabs = Styled(Tabs)`
  @media ${VIEWPORT_XS_MIN} {
    flex-direction: row;

    & > div {
      flex:1;
      border-top: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
      border-bottom: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
      border-right: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
      box-shadow: ${props => props.theme.shadow};
      transition: box-shadow ${props => props.theme.animationDuration} ease-out;
      background: ${props => props.theme.colorBaseLight90};

      &:first-child {
        border-top-left-radius: .125em;
        border-bottom-left-radius: .125em;
        border-left: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
      }

      &:last-child {
        border-top-right-radius: .125em;
        border-bottom-right-radius: .125em;
        border-right: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
      }
    }
  }
`;

export const Input = Styled.input`
  /* This element must be kept in render context to keep tab index functionality! */
  #${WIDGET_ROOT_TAG} & {
    position: absolute;
    left: -100vw;
    width: 0;
    height: 0;
    background: transparent;
    border: none;
    z-index: -1;
  }
`;

export const Radio = Styled.label`
  #${WIDGET_ROOT_TAG} & {
    display: block;
    position:relative;
    font-size:1em;
    min-width: 1.25em;
    min-height: 1.25em;
    max-width: 1.25em;
    max-height: 1.25em;
    margin:0;
    margin-right:.5em;
    border: ${borderWidth} solid ${props => props.theme.borderColor};
    border-radius: 50%;
    color: ${props => props.theme.radioColor};
    background-color: ${props => props.theme.backgroundColor};
    box-shadow: ${props => props.theme.shadow};
    transition: box-shadow ${animationDuration} ease-out;
    cursor: pointer;
    user-select: none;
  }

 #${WIDGET_ROOT_TAG} &:hover {
    border-color: ${props => props.theme.borderColorHover};
 }
`;

export const Bubble = Styled.div`
    position:absolute;
    top: 0.3em;
    left: 0.3em;
    content: '';
    height: 0.5em;
    width: 0.5em;
    border-radius: 50%;
    background: ${props => props.theme.radioColor};
    transform: scale3d(0, 0, 1);
    transition: transform ${animationDuration} cubic-bezier(.5,2,.5,1);
`;

export const Tab = Styled.label`
  #${WIDGET_ROOT_TAG} & {
    margin: 0;
    cursor: pointer;
    position: relative;
    padding: 1em 1em;
    background: ${props => props.theme.colorBaseLight90};
    height:100%;
    display:flex;
    flex-direction: row;
    align-items:center;
    font-size: 0.875em;
    line-height: 24px;
    user-select: none;
    justify-content: flex-start;
    font-weight: normal;
  }

  #${WIDGET_ROOT_TAG} ${Input}:checked ~ & {
    background: ${props => props.theme.checkColor};
    color: ${props => props.theme.labelColorChecked};
  }

  #${WIDGET_ROOT_TAG} ${Input}:focus ~ & {
    background: ${props => props.theme.checkColor};
    color: ${props => props.theme.labelColorFocus};
  }

  #${WIDGET_ROOT_TAG} ${Input}:focus ~ & > ${Radio} {
    border-color: ${props => props.theme.borderColorFocus};
    box-shadow: ${props => props.theme.shadowFocus};
  }

  #${WIDGET_ROOT_TAG} ${Input}:checked ~ & > ${Radio} {
    background-color: ${props => props.theme.backgroundColorChecked};
    border-color: ${props => props.theme.borderColorChecked};
  }

  #${WIDGET_ROOT_TAG} ${Input}:checked ~ & > ${Radio} > ${Bubble} {
     transform: scale3d(1, 1, 1);
  }
`;

export const ResponsiveTab = Styled(Tab)`
  @media ${VIEWPORT_XS_MIN} {
    #${WIDGET_ROOT_TAG} & {
      justify-content: center;
    }
  }
`;
