import fetch from 'isomorphic-fetch';
import {
  getSessionStorage,
  setSessionStorage,
} from '../session/sessionHandler';

import { MOCK_PAAS_PRODUCTS_URL } from '../mockData';

export const KEY_OFFERS = 'ofrs';
export const KEY_OFFERS_DATE = 'ofrsDt';

const paasProductsUrl =
  process.env.REACT_APP_PAAS_PRODUCTS_URL || MOCK_PAAS_PRODUCTS_URL;

const isIE = () =>
  navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1;

const getOffersFromPaas = advertisementId => new Promise((resolve, reject) => {
  const url = `${paasProductsUrl}/${advertisementId}`;

  if (isIE()) {
    const request = new XMLHttpRequest();

    request.onload = () => {
      const { response: responseString } = request;
      const responseJSON = JSON.parse(responseString);
      resolve(responseJSON);
    };
    request.onerror = (error) => {
      reject(error);
    };

    request.open(
      'GET',
      url,
      true,
    );
    request.send(null);
  }
  else {
    fetch(url, { }).then(response => resolve(response.json())).catch(reject);
  }
});

/*
 * Fetch Offers from Paas products endpoint for specified advertisementId
 */
export async function fetchOffers(advertisementId) {
  const response = await getOffersFromPaas(advertisementId);
  return response;
}

/*
 * Cache Offers for one hour (3600000 ms)
 */
export const getCachedOffers = async (advertisementId) => {
  const { ofrs, ofrsDt } = getSessionStorage();

  let offers = ofrs ? JSON.parse(ofrs) : undefined;
  if (!offers || ((Date.now() - ofrsDt) >= 3600000)) {
    try {
      offers = await fetchOffers(advertisementId);

      // Only Save to SessionStorage if repsonse is valid.
      if (offers.status === 200 || offers.errors.length === 0) {
        setSessionStorage(KEY_OFFERS, JSON.stringify(offers));
        setSessionStorage(KEY_OFFERS_DATE, Date.now());
      }
    }
    catch (err) {
      offers = await fetchOffers(process.env.REACT_APP_PAAS_ADVERTISEMENT_ID);
    }
  }
  return offers;
};
