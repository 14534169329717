import React from 'react';
import PropTypes from 'prop-types';

import { formatAmount } from '../../util/numberFormatter';

import {
  Wrapper,
  Label,
  Value,
} from './CafeHeader.style';


/**
 * Cafe Header containing amount and
 *
 * @return {JSX}
 */
const CafeHeader = ({ amount, term }) => (
  <Wrapper>
    <div>
      <Value>{formatAmount(amount, '€')}</Value>
      <Label>Nettodarlehensbetrag</Label>
    </div>
    <div>
      <Value>{formatAmount(term, 'Monate')}</Value>
      <Label>Laufzeit</Label>
    </div>
  </Wrapper>
);

CafeHeader.propTypes = {
  amount: PropTypes.number.isRequired,
  term: PropTypes.number.isRequired,
};

export default CafeHeader;
