import React from 'react';
import { withTheme } from 'styled-components';

import PropTypes from 'prop-types';
import TextFieldLogic from './TextFieldLogic';
import FFGTextFieldLook from './ffg/TextField';
import ScoutTextFieldLook from './scout/TextField';
import MaterialTextFieldLook from './material/TextField';

const TextField = (props) => {
  let TextFieldClass = FFGTextFieldLook;
  let { lookAndFeel } = props.theme;
  if (props.lookAndFeel) {
    // eslint-disable-next-line prefer-destructuring
    lookAndFeel = props.lookAndFeel;
  }


  switch (lookAndFeel) {
    case 'Scout':
      TextFieldClass = ScoutTextFieldLook;
      break;
    case 'Material':
      TextFieldClass = MaterialTextFieldLook;
      break;
    default:
      TextFieldClass = FFGTextFieldLook;
  }

  return <TextFieldLogic {...props} componentClass={TextFieldClass} />;
};

TextField.defaultProps = {
  lookAndFeel: undefined,
};

TextField.propTypes = {
  lookAndFeel: PropTypes.string,
  theme: PropTypes.shape({
    lookAndFeel: PropTypes.string,
  }).isRequired,
};

export default withTheme(TextField);
