import {
  primary,
  primaryLight20,
  primaryLight40,
  primaryLight90,
  secondary,
  secondaryLight90,
  secondaryLight40,
  secondaryLight20,
  secondaryDark40,
  base,
  baseLight90,
  baseLight80,
  baseLight40,
  baseLight20,
  baseDark80,
  baseDark40,
  success,
  successLight60,
  warning,
  warningLight60,
  danger,
  dangerLight60,
} from '@finanzcheck/colors';

import { createMuiTheme } from '@material-ui/core/styles';


export const theme = {
  colorPrimary: primary,
  colorPrimaryLight90: primaryLight90,
  colorPrimaryLight40: primaryLight40,
  colorPrimaryLight20: primaryLight20,
  colorSecondary: secondary,
  colorSecondaryLight90: secondaryLight90,
  colorSecondaryLight40: secondaryLight40,
  colorSecondaryLight20: secondaryLight20,
  colorSecondaryDark40: secondaryDark40,
  colorBase: base,
  colorBaseLight90: baseLight90,
  colorBaseLight80: baseLight80,
  colorBaseLight40: baseLight40,
  colorBaseLight20: baseLight20,
  colorBaseDark80: baseDark80,
  colorBaseDark40: baseDark40,
  colorSuccess: success,
  colorSuccessLight60: successLight60,
  colorWarning: warning,
  colorWarningLight60: warningLight60,
  colorError: danger,
  colorErrorLight60: dangerLight60,
  color: baseDark40,
  colorInvalid: danger,
  backgroundColorChecked: secondary,
  borderColor: baseLight40,
  borderColorValid: success,
  borderColorInvalid: danger,
  borderColorHover: baseDark40,
  borderColorFocus: secondaryLight20,
  borderColorChecked: secondary,
  labelColor: base,
  labelColorFocus: secondary,
  labelColorInvalid: danger,
  labelColorChecked: baseDark40,
  hintColor: base,
  unitColor: baseLight20,
  headlineColor: secondary,
  backgroundColor: '#fff',
  checkColor: '#fff',
  borderWidth: '.0625em',
  animationDuration: '.3s',
  radioColor: '#fff',
  placeholderColor: '#b1c0c7',
  fontSize: '16px',
  fontFamily: 'Lato, sans-serif',
  iconWidth: '2.5em',
  paddingVertical: '1em',
  shadow: '0 0 0 #fff',
  shadowFocus: '0 0 5px 1px #62abff',
  shadowInvalid: '0 0 5px 1px #ff6262',
  shadowValid: '0 0 5px #62ff65',
};


export const MUITheme = createMuiTheme({
  palette: {
    primary: {
      main: secondary,
    },
    secondary: {
      main: secondary,
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        marginBottom: 0,
      },
    },
    MuiFilledInput: {
      input: {
        '&::placeholder': {
          color: 'gray',
        },
        height: 'unset',
        color: secondary, // if you also want to change the color of the input, this is the prop you'd use
      },
      root: {
        backgroundColor: '#f5f6f7',
        '&:hover': {
          backgroundColor: '#f5f6f7',
          '@media (hover: none)': {
            backgroundColor: '#f5f6f7',
          },
        },
        '&$focused': {
          backgroundColor: '#f5f6f7',
        },
      },
    },
  },
});
