import styled from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';

const MODIFIER_FRAME = {
  isInvalid: ({ theme }) => `
    border-color: ${theme.borderColorInvalid};
    background: ${theme.shadowInvalid};`,
  isInValidAndHasFocus: ({ theme }) => `
    background: ${theme.shadowInvalid};`,
  isValidAndHasFocus: ({ theme }) => `
    border-color: ${theme.borderColorValid};
    background: ${theme.shadowValid};`,
  hasFocus: ({ theme }) => `
    border-color: ${theme.borderColorFocus};
    background: ${theme.shadowFocus};`,
};

export const Frame = styled.div`
  position:relative;
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
    
  border: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
  border-radius: 4px;
  box-shadow: ${props => props.theme.shadow};
  transition: all ${props => props.theme.animationDuration} ease-out;
  
  z-index: 0;
  background-color: ${props => props.theme.backgroundColor};
  
  &:hover{
  border-color: ${props => props.theme.borderColorHover}
  }
  
  ${applyStyleModifiers(MODIFIER_FRAME)}
`;

Frame.propTypes = {
  /**
   * Modifiers
   */
  modifiers: styleModifierPropTypes(MODIFIER_FRAME),
};

export default Frame;
