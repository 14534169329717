import flatten from 'lodash.flatten';


const amountInRange = amount => ({ amountMin, amountMax }) => amount >= amountMin && amount <= amountMax;
const termInRange = term => ({ termMin, termMax }) => term >= termMin && term <= termMax;
const sortByRate = rate => ({ [rate]: rateA }, { [rate]: rateB }) => rateA - rateB;
const hasPurpose = purpose => ({ purposes = [] }) => purposes.includes(purpose);

const getBestOffer = (products, { term, purpose, amount }) => {
  // Get Flat List of all Conditions
  let validConditions = flatten(
    products.map(
      ({ conditions, ...product }) =>
        conditions.map(condition =>
          ({ ...condition, ...product })),
    ),
  );

  // Filter by Amount
  if (amount) {
    validConditions = validConditions.filter(amountInRange(amount));
  }

  // Filter by Term
  if (term) {
    validConditions = validConditions.filter(termInRange(term));
  }

  // Filter by purpose
  if (purpose) {
    validConditions = validConditions.filter(hasPurpose(purpose));
  }

  // Sort for Best Rate
  const sortedValidConditions = validConditions.sort(sortByRate('effectiveInterestRate'));
  const [bestCondition] = sortedValidConditions;
  return bestCondition;
};

export default getBestOffer;
