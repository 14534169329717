/* eslint-disable react/no-unused-state */

import React from 'react';
import PropTypes from 'prop-types';

import { randomHash } from '../../util/random';

class TextFieldLogic extends React.PureComponent {

  static propTypes = {
    componentClass: PropTypes.func.isRequired,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    pattern: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    label: PropTypes.string.isRequired,
    unit: PropTypes.string,
    hint: PropTypes.string,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    noErrorMessage: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onKeyDown: PropTypes.func,
    onInput: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    id: '',
    type: 'text',
    pattern: undefined,
    minLength: undefined,
    maxLength: undefined,
    unit: '',
    hint: '',
    placeholder: ' ', // CSS hack vor :placeholder-shown selector
    value: '', // zero length value required for correct CSS behaviour
    autoComplete: 'on',
    required: false,
    disabled: false,
    noErrorMessage: false,
    onKeyDown: () => { },
    onInput: () => { },
    onChange: () => { },
    onBlur: () => { },
  };

  constructor(props) {
    super(props);

    const {
      required,
      disabled,
      value,
      id,
    } = this.props;

    this.state = {
      value,
      isRequired: required,
      isDisabled: disabled,
      hasFocus: false,
      wasTouched: false,
      isDirty: false,
      isValid: undefined,
      error: '',
    };

    this.id = id || randomHash();
    this.inputElement = null;
  }

  /**
   * Sends focus to the input
   */
  focusInput = () => {
    this.inputElement.focus();
  };

  /**
   * Clears the input field
   *
   * @param {Event} event - event from input
   */
  clearInput = (event) => {
    event.preventDefault();
    const { inputElement } = this;

    inputElement.value = ''; // manually set value here to run custom validation
    inputElement.setCustomValidity('');
    this.updateCustomValidity(inputElement);

    this.setState({
      value: '',
      hasFocus: false,
      wasTouched: true,
      isValid: inputElement.validity.valid,
      error: inputElement.validationMessage,
    });
  };

  /**
   * Adds custom validity messages directly to the input.
   *
   * @param {HTMLInputElement} input
   */
  updateCustomValidity = (input) => {
    const validityState = input.validity;

    // reset custom validity and run the validation chain again
    if (validityState.customError) return;

    let error = '';

    if (validityState.valueMissing) {
      error = 'Dieses Feld müssen Sie ausfüllen.';
    }

    input.setCustomValidity(error);
  };

  handleFocus = () => {
    this.setState({
      hasFocus: true,
    });
  };

  handleKeyDown = (event) => {
    // no own event handling up to now

    this.props.onKeyDown(event);
  };

  handleInput = (event) => {
    const { target } = event;

    // reset custom error (base component only - don't do this in extends!)
    target.setCustomValidity('');

    this.updateCustomValidity(target);

    // call external handlers before saving value and validity to state
    this.props.onInput(event, target.value);

    let newState = {
      isDirty: true,
      value: target.value.trim(),
    };

    // do not mark errors instantly when input is used first time
    if (this.state.isValid === false || this.state.wasTouched) {
      newState = {
        ...newState,
        isValid: target.validity.valid,
        error: target.validationMessage,
      };
    }

    this.setState(newState);
  };

  handleChange = (event) => {
    const { target } = event;
    const cleanValue = target.value.trim();

    // call external handlers before saving validity to state
    this.props.onChange(event, cleanValue);

    this.setState({
      isValid: target.validity.valid,
      error: target.validationMessage,
    });
  };

  handleBlur = (event) => {
    const { target } = event;

    this.updateCustomValidity(event.target);

    // call external handlers before validation and before saving value to state
    this.props.onBlur(event);

    this.setState({
      value: target.value.trim(),
      wasTouched: true,
      hasFocus: false,
      isValid: target.validity.valid,
      error: target.validationMessage,
    });
  };

  handleInvalid = (event) => {
    event.preventDefault(); // prevent browser's error bubble
    const { target } = event;

    this.updateCustomValidity(target);

    this.setState({
      isValid: target.validity.valid,
      error: target.validationMessage,
    });
  };

  render() {
    const { componentClass: TextField } = this.props;

    return (
      <TextField
        id={this.id}
        name={this.props.name}
        placeholder={this.props.placeholder}
        autoComplete={this.props.autoComplete}
        type={this.props.type}
        pattern={this.props.pattern}
        minLength={this.props.minLength}
        maxLength={this.props.maxLength}
        inputRef={(node) => { this.inputElement = node; }}
        label={this.props.label}
        value={this.state.value}
        unit={this.props.unit}
        hint={this.props.hint}
        error={this.state.error}
        isRequired={this.state.isRequired}
        isDisabled={this.state.isDisabled}
        isValid={this.state.isValid}
        hasFocus={this.state.hasFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onInput={this.handleInput}
        onInvalid={this.handleInvalid}
        onKeyDown={this.handleKeyDown}
        clearInput={this.clearInput}
        noErrorMessage={this.props.noErrorMessage}
      />
    );
  }

}

export default TextFieldLogic;
