import React from 'react';

import { Loader } from './LoadingSpinner.style';

function LoadingSpinner() {
  return (
    <Loader>
      <svg width="24" height="24" viewBox="0 0 16 16">
        <path
          fill="#7B8A96"
          // eslint-disable-next-line max-len
          d="M16 7.958c0 1.092-.21 2.135-.634 3.128-.423.992-.99 1.847-1.704 2.564-.713.716-1.563 1.287-2.55 1.712C10.12 15.787 9.085 16 8 16c-1.086 0-2.123-.213-3.11-.638-.99-.425-1.84-.996-2.552-1.712-.713-.717-1.28-1.572-1.704-2.564C.21 10.093 0 9.05 0 7.958 0 6.636.3 5.4.898 4.248c.6-1.15 1.417-2.098 2.454-2.843C4.39.66 5.542.192 6.815 0v2.122c-1.364.28-2.496.965-3.394 2.057-.897 1.09-1.346 2.35-1.346 3.778 0 .807.157 1.578.472 2.313.315.736.736 1.37 1.264 1.9.528.53 1.158.954 1.89 1.27.73.317 1.498.475 2.3.475.802 0 1.57-.158 2.3-.475.732-.316 1.362-.74 1.89-1.27.528-.53.95-1.164 1.264-1.9.315-.734.472-1.505.472-2.312 0-1.427-.45-2.687-1.347-3.78-.9-1.09-2.03-1.777-3.395-2.056V0c1.272.192 2.426.66 3.463 1.405s1.855 1.693 2.454 2.844c.6 1.15.898 2.386.898 3.708z"
        />
      </svg>
    </Loader>
  );
}

export default LoadingSpinner;
