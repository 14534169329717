import PropTypes from 'prop-types';

import FemaleIcon from '../assets/icons/female.svg';
import MaleIcon from '../assets/icons/male.svg';
import { getCachedOffers } from './api/paasApi';
import filterOffers from './filterOffers';
import getBestOffer from './getBestOffer';
import { parseAmount } from './numberFormatter';
import { getSessionStorage } from './session/sessionHandler';
/**
 * @param {Number} count
 * @param {Number} multiplier
 * @param {String} label
 * @returns {Array}
 */
const generateNumberBasedSelectOptions = (
  count = 10,
  multiplier = 12,
  label = '',
) => {
  const options = [];

  for (let i = 1; i < count; i += 1) {
    const value = '' + multiplier * i;

    options.push({
      value,
      label: `${value} ${label}`,
    });
  }

  return options;
};

export const generateNumberBasedSelectOptionsRange = (
  min = 12,
  max = 84,
  increment = 12,
  label = 'Monate',
) => {
  const options = [];

  for (let i = min; i <= max; i += increment) {
    const value = '' + i;
    options.push({
      value,
      label: `${value} ${label}`,
    });
  }

  return options;
};

export const OptionType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.func,
});
export const salutationOptions = [
  {
    value: 'm',
    label: 'Herr',
    icon: MaleIcon,
  },
  {
    value: 'w',
    label: 'Frau',
    icon: FemaleIcon,
  },
];

export const relationOptions = [
  {
    value: 'SPOUSE',
    label: 'Ehepartner',
  },
  {
    value: 'PARTNER',
    label: 'Lebenspartner',
  },
  {
    value: 'OTHER',
    label: 'andere Beziehung',
  },
];

export const termOptions = generateNumberBasedSelectOptions(10, 12, 'Monate');

export const purposeOptions = [
  {
    value: 'REFINANCING',
    label: 'Umschuldung',
  },
  {
    value: 'PRE_OWNED_CAR',
    label: 'Gebrauchtfahrzeug',
  },
  {
    value: 'NEW_CAR',
    label: 'Neufahrzeug',
  },
  {
    value: 'FURNITURE',
    label: 'Einrichtung / Möbel',
  },
  {
    value: 'RENOVATION',
    label: 'Renovierung',
  },
  {
    value: 'HOLIDAY',
    label: 'Urlaub',
  },
  {
    value: 'ENTERTAINMENT_ELECTRONICS',
    label: 'PC / TV / HiFi / Video',
  },
  {
    value: 'ACCOUNT_BALANCE',
    label: 'Ausgleich Girokonto',
  },
  {
    value: 'MOVE',
    label: 'Umzug',
  },
  {
    value: 'REAL_ESTATE_FINANCING',
    label: 'Immobilienfinanzierung',
  },
  {
    value: 'OTHER',
    label: 'Freie Verwendung',
  },
];

export const getPurposeOptions = purposes =>
  (!purposes
    ? purposeOptions
    : purposeOptions.filter(option => purposes.indexOf(option.value) !== -1));

export const getDebtorObj = (formData) => {
  // used by BankForm
  if (formData.has('debtors')) {
    if (formData.get('debtors') === '2') {
      return {
        count: 2,
        relationship: formData.get('relation'),
      };
    }
    return {
      count: 1,
    };
  }

  let secondary = formData.has('secondary') ? formData.get('secondary') : false;
  // MaterialLeadForm uses String values `true` and `false` as it is using Radio Buttons instead of a Checkbox
  if (typeof secondary === 'string') {
    secondary = secondary === 'true';
  }

  // used by LeadForms
  if (!secondary) {
    return {
      count: 1,
      primary: {
        personal: {
          gender: formData.get('salutation') === 'w' ? 'FEMALE' : 'MALE',
          firstname: formData.get('firstName'),
          lastname: formData.get('lastName'),
        },
        contact: {
          email: formData.get('email'),
          phoneNumber: formData.get('phone'),
        },
      },
    };
  }
  return {
    count: 2,
    isSameContactInformation: true,
    relationship: formData.get('relation'),
    primary: {
      personal: {
        gender: formData.get('salutation') === 'w' ? 'FEMALE' : 'MALE',
        firstname: formData.get('firstName'),
        lastname: formData.get('lastName'),
      },
      contact: {
        email: formData.get('email'),
        phoneNumber: formData.get('phone'),
      },
    },
    secondary: {
      personal: {
        gender: formData.get('salutation2') === 'w' ? 'FEMALE' : 'MALE',
        firstname: formData.get('firstName2'),
        lastname: formData.get('lastName2'),
      },
      contact: {
        email: formData.get('email'),
        phoneNumber: formData.get('phone'),
      },
    },
  };
};

/**
 * Map of new PAAS purpose values to old purpose values used in CAFE
 * @type {Object}
 */
export const purposeMap = {
  REFINANCING: 'refinancing',
  PRE_OWNED_CAR: 'pre_owned_car',
  NEW_CAR: 'new_car',
  FURNITURE: 'furniture',
  RENOVATION: 'renovation',
  HOLIDAY: 'holiday',
  ENTERTAINMENT_ELECTRONICS: 'entertainment_electronics',
  ACCOUNT_BALANCE: 'account_balance',
  MOVE: 'move',
  REAL_ESTATE_FINANCING: 'real_estate_financing',
  OTHER: 'other',
};

/**
 * Map purpose to old CAFE purpose value
 * @param  {string} purpose Purpose of the loan, i.e. 'OTHER'
 * @return {string}         CAFE purpose value, i.e. 'other'
 */
export const mapPurposeToCafePurpose = purpose =>
  (purposeMap[purpose] ? purposeMap[purpose] : 'other');

/**
 * Build a request object compatible with PAAS API based upon given form data
 *
 * @param {FormData} formData
 * @param {Object} affiliateData
 * @returns {Object}
 */
export const buildRequestData = (formData, affiliateData, isScuf = false) => {
  const { advertisementId, subId, parameters } = affiliateData;
  const { bid } = getSessionStorage();

  const result = {
    ...parameters,
    advertisementId,
    subId: subId ? subId.replace(/\s/g, '_') : undefined,
    resumeHash: formData.get('resumeHash'),
    amount: parseAmount(formData.get('amount')),
    term: parseInt(formData.get('term'), 10),
    purpose: formData.get('purpose'),
    browserId: bid,
    debtors: getDebtorObj(formData),
  };

  if (formData.has('terms') || isScuf) {
    result.termsAccepted = (formData.get('terms') !== null) || isScuf;
    result.dataProcessingConsentGiven = formData.get('dataProcessingConsent') !== null;
  }

  return result;
};

/**
 * Get the best effectiveInterestRate condition which fits the passed amount
 * @param  {number} amount           an amount which should be optainable by the condition
 * @param  {string} advertisementId  ID of the advertisement
 * @return {object}                  a single condition object or undefined
 */
export const getBestRateForAmount = async (amount, advertisementId) => {
  const { result: { products } } = await getCachedOffers(advertisementId);

  return getBestOffer(products, { amount });
};

/**
 * Get cheapest offer by a given amount, term and purpose.
 * @param  {integer}  amount          Amount desired
 * @param  {integer}  term            Number of months the loan should run
 * @param  {string}  purpose          Purpose of the loan
 * @param  {string}   advertisementId ID of the advertisement
 * @return {Promise}
 */
export const getOfferByBestRate = async (
  { amount, term, purpose },
  advertisementId,
) => {
  const { result: { products } } = await getCachedOffers(advertisementId);

  return getBestOffer(products, { amount, term, purpose });
};

/**
 * Get cheapest offer by a desired monthly rate, term and purpose
 * @param  {integer}  rate            Desired monthly rate
 * @param  {integer}  term            Number of months the loan should run
 * @param  {string}  purpose          Purpose of the loan
 * @param  {string}   advertisementId ID of the advertisement
 * @return {Promise}
 */
export const getBestOfferByMonthlyRate = async (
  { rate, term, purpose },
  advertisementId,
) => {
  const { result: { products } } = await getCachedOffers(advertisementId);

  return getBestOffer(products, { amount: rate * term, purpose, term });
};

const OLD_OFINA_PRODUCT_IDS = ['218', '247'];
/**
 * Product filter criteria
 * including mapping of old ofina product to new ones
 */
const productFilter = (product, productId) => (
  product.id === productId ||
  (OLD_OFINA_PRODUCT_IDS.includes(productId) && product.id === process.env.REACT_APP_OFINA_PRODUCT_ID)
);

/**
 * Get cheapest offer with the given product.
 * @param  {string}  productId        Product ID
 * @param  {integer}  amount          Amount desired
 * @param  {integer}  term            Number of months the loan should run
 * @param  {string}  purpose          Purpose of the loan
 * @param  {string}   advertisementId ID of the advertisement
 * @return {Promise}
 */
export const getProductOfferByBestRate = async (
  productId,
  { amount, term, purpose },
  advertisementId,
) => {
  const offers = await getCachedOffers(advertisementId);
  const products = offers.result.products.filter(
    product => productFilter(product, productId),
  );

  const filterOptions = {
    offers: products,
    purpose,
    amount,
    term,
  };

  const [bestOffer] = filterOffers(filterOptions);
  return bestOffer;
};

/**
 * Builds aggregated condition sets over all given products.
 * @param products {array} list of products
 * @param mapping {object} mapping, keys must be condition properties, values must function to select the desired value
 */
function aggregateConditions(products, mapping) {
  // aggregate all conditions sets
  const conditions = products
    .map(product => product.conditions)
    .reduce((a, b) => a.concat(b), []);
  // build result via mapping
  const result = {};
  Object.keys(mapping).forEach((key) => {
    const func = mapping[key];
    result[key] = func(...conditions.map(condition => condition[key]));
  });
  return result;
}

// https://stackoverflow.com/a/27664971/804614
function mergeDedupe(arr) {
  return [...new Set([].concat(...arr))];
}

/**
 * Aggregates a set of valid conditions from all allowed products.
 * @param productId Product ID
 * @param advertisementId ID o f the advertisement
 */
export const getValidConditions = async (productId, advertisementId) => {
  const offers = await getCachedOffers(advertisementId);
  const products = productId
    ? offers.result.products.filter(product => productFilter(product, productId))
    : offers.result.products;

  const conditions = aggregateConditions(products, {
    amountMin: Math.min,
    amountMax: Math.max,
    termMin: Math.min,
    termMax: Math.max,
  });

  conditions.purposes = mergeDedupe(products.map(product => product.purposes));

  return conditions;
};

export const getProduct = async (productId, advertisementId) => {
  const offers = await getCachedOffers(advertisementId);
  if (!offers || !offers.result) return null;

  const products = offers.result.products.filter(
    product => productFilter(product, productId),
  );

  if (products.length !== 1) return null;

  return products[0];
};

/**
 * Build a valid URL including querystring parameters
 * @param  {string} url          Base URL
 * @param  {Object} [options={}] Querystring parameters to add
 * @return {string}              URL containing query string parameters
 */
export const buildTargetUrl = (url, options = {}) => {
  const urlObject = new URL(url);
  Object.keys(options).forEach((key) => {
    if (options[key]) urlObject.searchParams.set(key, options[key]);
  });
  return urlObject.toString();
};


/**
 * Turns strings like 01.2019 and 01.01.2019 into a date object.
 * @param  {string} string     Date in the described form as string
 * @return {Date}              Input string represented as a Date Object
 */
export const turnIntoDateObject = (string) => {
  const [day, month, year] = string.split('.').map(v => Number(v, 10));
  const dateObj = new Date();
  if (year) {
    dateObj.setFullYear(year);
    dateObj.setMonth(month - 1);
    dateObj.setDate(day);
  }
  else {
    dateObj.setFullYear(month);
    dateObj.setMonth(day - 1);
    dateObj.setDate(1);
  }

  return dateObj;
};
