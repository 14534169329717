import React from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

export const WidgetSizeContext = React.createContext({ width: null, height: null, checkWidgetSize: () => { } });

function WidgetSizeProvider({ domNodeToObserve, children }) {
  const [widgetSize, setWidgetSize] = React.useState({ width: null, height: null, checkWidgetSize: () => { } });

  const updateWidgetSize = React.useCallback(debounce(() => {
    const { width, height } = domNodeToObserve.getBoundingClientRect();
    setWidgetSize({ width, height });
  }, 500), [setWidgetSize, domNodeToObserve]);

  React.useEffect(() => {
    window.addEventListener('resize', updateWidgetSize);
    // set inital size on mount
    updateWidgetSize();
    return () => {
      window.removeEventListener(updateWidgetSize);
    };
  }, [domNodeToObserve]);

  return (<WidgetSizeContext.Provider value={widgetSize}>{children}</WidgetSizeContext.Provider>);
}

WidgetSizeProvider.propTypes = {
  domNodeToObserve: PropTypes.object.isRequired,
  children: PropTypes.any,
};
WidgetSizeProvider.defaultProps = {
  children: null,
};


const WidgetSizeConsumer = WidgetSizeContext.Consumer;
export { WidgetSizeProvider, WidgetSizeConsumer };
