import Styled from 'styled-components';


export const DIRECTION_HORIZONTAL = 'horizontal';
export const DIRECTION_VERTICAL = 'vertical';

// This is the current color-set used in this component
export const theme = {
  backgroundColor: '#FFFFFF',
  backgroundColorChecked: '#165297',
  borderColor: '#B0B9C0',
  borderColorHover: '#4A535A',
  borderColorFocus: '#4575AC',
  borderColorInvalid: '#FF4942',
  borderColorChecked: '#165297',
  radioColor: '#FFFFFF',
  labelColor: '#7B8A96',
  labelColorFocus: '#165297',
  labelColorChecked: '#4A535A',
  labelColorInvalid: '#FF4942',
  shadow: '0 0 0 transparent',
  shadowFocus: '0 0 .375em .0625em #62ABFF',
  shadowInvalid: '0 0 .375em .0625em #FF6262',
};

const borderWidth = '0.0625em';
const animationDuration = '0.3s';


export const Container = Styled.fieldset`
  display: block;
  margin: .25em 0 1em;
  padding: 0;
  border: none;
`;


export const Label = Styled.legend`
  display: block;
  font-size: 0.875em;
  line-height: 1em;
  color: ${props => props.theme.labelColor};
  transform: scale3d(0.85, 0.85, 1);
  transform-origin: 0 0;

  ${props => props.hasFocus && `
    color: ${props.theme.labelColorFocus};
  `}
  ${props => props.isValid === false && `
    color: ${props.theme.labelColorInvalid};
  `};
`;


export const Options = Styled.div`
  display: flex;
  flex-direction: ${(props) => {
    switch (props.optionsDirection) {
      case DIRECTION_VERTICAL:
        return 'column';
      case DIRECTION_HORIZONTAL:
      default:
        return 'row';
    }
  }};
  flex-wrap: wrap;
  margin: .5em 0 0;
`;


export const Option = Styled.div`
  position: relative;
  margin-bottom: .75em;
  margin-right: 1em;
  padding-left: 2em;
`;


export const Input = Styled.input`
  /* This element must be kept in render context to keep tab index funcitonality! */
  position: absolute;
  left: -100vw;
  width: 0;
  height: 0;
  background: transparent;
  border: none;
  z-index: -1;
`;


export const Radio = Styled.label`
  position: absolute;
  top: 0.125em;
  left: 0;
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  color: ${props => props.theme.radioColor};
  background-color: ${props => props.theme.backgroundColor};
  border: ${borderWidth} solid ${props => props.theme.borderColor};
  border-radius: 50%;
  box-shadow: ${props => props.theme.shadow};
  transition: box-shadow ${animationDuration} ease-out;
  cursor: pointer;
  user-select: none;

  &:hover {
    border-color: ${props => props.theme.borderColorHover};
  }
  ${Input}:focus ~ & {
    border-color: ${props => props.theme.borderColorFocus};
    box-shadow: ${props => props.theme.shadowFocus};
  }
  ${Input}:checked ~ & {
    background-color: ${props => props.theme.backgroundColorChecked};
    border-color: ${props => props.theme.borderColorChecked};
  }
  ${Input} ~ &, /* TODO find better solution to empower specifity */
  ${Input}:focus ~ & {
    ${props => props.isValid === false && `
      border-color: ${props.theme.borderColorInvalid};
    `}
  }
  ${Input}:focus ~ & {
    ${props => props.isValid === false && `
      box-shadow: ${props.theme.shadowInvalid};
    `}
  }

  &::before {
    content: '';
    position: absolute;
    top: 0.3125em;
    left: 0.3125em;
    height: 0.5em;
    width: 0.5em;
    border-radius: 50%;
    background: ${props => props.theme.radioColor};
    transform: scale3d(0, 0, 1);
    transition: transform ${animationDuration} cubic-bezier(.5,2,.5,1);

    ${Input}:checked ~ & {
      transform: scale3d(1, 1, 1);
    }
  }
`;


export const OptionLabel = Styled.label`
  font-size: 0.875em;
  line-height: 1em;
  color: ${props => props.theme.labelColor};

  ${Input}:checked ~ & {
    color: ${props => props.theme.labelColorChecked};
  };
  ${Input}:focus ~ & {
    color: ${props => props.theme.labelColorFocus};
  }
  ${Input} ~ &, /* TODO find better solution to empower specifity */
  ${Input}:focus ~ & {
    ${props => props.isValid === false && `
      color: ${props.theme.labelColorInvalid};
    `}
  }
`;

export default Container;
