import React from 'react';

import { TextInput } from './textInput';
import { TextFieldUnit } from './textFieldUnit';
import { TextFieldPropTypes, TextFieldDefaultProps } from '../PropTypes';
import Field from '../../Field';

class TextField extends React.PureComponent {

  static propTypes = TextFieldPropTypes;

  static defaultProps = TextFieldDefaultProps;

  render() {
    return (
      <Field
        htmlFor={this.props.id}
        label={this.props.label}
        error={this.props.error}
        hint={this.props.hint}
        hasFocus={this.props.hasFocus}
        isValid={this.props.isValid}
      >
        <TextInput
          ref={this.props.inputRef}
          id={this.props.id}
          value={this.props.value}
          name={this.props.name}
          type={this.props.type}
          pattern={this.props.pattern}
          minLength={this.props.minLength}
          maxLength={this.props.maxLength}
          autoComplete={this.props.autoComplete}
          placeholder={this.props.placeholder}
          required={this.props.isRequired}
          disabled={this.props.isDisabled}
          onFocus={this.props.onFocus}
          onKeyDown={this.props.onKeyDown}
          onInput={this.props.onInput}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onInvalid={this.props.onInvalid}
        />

        { this.props.unit && (
        <TextFieldUnit
          modifiers={[
            ...(this.props.hasFocus ? ['hasFocus'] : []),
            ...(this.props.isValid === false ? ['isInvalid'] : []),
          ]}
        >
          {this.props.unit}
        </TextFieldUnit>
        )}
      </Field>
    );
  }

}

export default TextField;
