import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import FFG from './ffg/Button';
import Scout from './scout/Button';

const Button = (props) => {
  let Component = FFG;

  switch (props.theme.lookAndFeel) {
    case 'Scout':
      Component = Scout;
      break;
    default:
      Component = FFG;
  }

  return <Component {...props} />;
};

Button.propTypes = {
  theme: PropTypes.shape({
    lookAndFeel: PropTypes.string,
  }).isRequired,
};

export default withTheme(Button);
