import queryString from 'query-string';
import * as Ids from './Ids';
import { extractAffiliateData } from './util/extractAffiliateData';


let thisElement = null;
if (document.currentScript) {
  thisElement = document.currentScript;
}
else {
  const THIS_ID = Ids.WIDGET_SCRIPT_TAG;
  const THIS_SRC = /(\.finanzcheck[a-z-]*\.de|localhost|10\.0\.2\.2|10\.8\.80\.\d+)(:\d+)?\/.*\.js$/;
  thisElement = document.getElementById(THIS_ID) || Array.prototype.slice.call(document.scripts).filter(script => (
    script.src && script.src.match(THIS_SRC)
  ))[0];
}

if (!thisElement) throw new Error('HTMLScriptElement reference could not be determined.');
const widgetRoot = document.createElement('div');
widgetRoot.id = Ids.WIDGET_ROOT_TAG;
widgetRoot.style = 'width:100%; height:100%;';

const elementAttributes = Object.values(thisElement.attributes).reduce((prev, { name, value }) => ({ ...prev, [name]: value }), {});
const queryAttributes = queryString.parse(document.location.search);

const { styles, affiliateData } = extractAffiliateData(elementAttributes);
const { affiliateData: affiliateDataQueryString, styles: stylesQueryString } = extractAffiliateData(queryAttributes);

const combinedStyles = { ...styles, ...stylesQueryString };
const widgetVariables = Object.assign({
  bodyColor: '#f2f2f2',
  buttonAnimationDuration: '0.15s',
  buttonBackgroundColor: '#F09103',
  buttonBackgroundColorActive: '#F3A735',
  buttonBackgroundColorDisabled: '#E5E8EA',
  buttonBorderColor: 'transparent',
  buttonBorderColorFocus: 'transparent',
  buttonBorderColorHover: 'transparent',
  buttonOutlineColor: '#0077ff',
  buttonShadowColor: '#00000033',
  buttonTextColor: '#FFFFFF',
  linkColor: '#165297',
  linkColorActive: '#7397C1',
  linkColorHover: '#165297',
  transformHover: 'translateY(0.05rem)',
}, combinedStyles);
const combinedVariables = {
  buttonShadow: '0 1.5rem 1.25rem -0.975rem ' + widgetVariables.buttonShadowColor,
  buttonShadowActive: '0 0 0 -0.975rem ' + widgetVariables.buttonShadowColor,
  buttonShadowFocus: '0 0 5px ' + widgetVariables.buttonOutlineColor,
  buttonShadowHover: '0 0.75rem 0.75rem -0.5rem ' + widgetVariables.buttonShadowColor,
};

// Insert Widget Root into DOM
thisElement.parentNode.insertBefore(widgetRoot, thisElement.nextSibling);

const scriptTag = thisElement;
export { affiliateData, affiliateDataQueryString, combinedStyles, combinedVariables, widgetVariables, scriptTag, widgetRoot };
