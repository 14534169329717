import Styled from 'styled-components';

export default Styled.div`
  opacity: 0;
  width:1px;
  height:1px;
  position:absolute;
  top:0;

`;
