import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';

import styled from 'styled-components';
import { TextFieldDefaultProps, TextFieldPropTypes } from '../PropTypes';
import { WIDGET_ROOT_TAG } from '../../../Ids';


/**
 * Specific overrides to prevent styling from host sites. HAW-505
 */
const FormHelperTextWithStyles = styled(FormHelperText)`
  #${WIDGET_ROOT_TAG} & {
    margin-bottom: 0;
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  #${WIDGET_ROOT_TAG} & {
    p {
      margin:0 ;
    }
  }
`;


const TextField = (props) => {
  const {
    clearInput,
    error,
    hasFocus,
    hint,
    id,
    inputRef,
    isDisabled,
    isRequired,
    isValid,
    label,
    onBlur,
    onFocus,
    onInput,
    onInvalid,
    onKeyDown,
    type,
    unit,
    value,
    noErrorMessage,
    ...inputProps
  } = props;


  return (
    <FormControl
      error={!!error && isValid !== undefined && !isValid && hasFocus !== undefined && !hasFocus}
      required={isRequired}
      disabled={isDisabled}
    >
      <InputLabel shrink variant="filled" htmlFor={id}>{label}</InputLabel>
      <FilledInput
        id={id}
        type={type}
        inputProps={inputProps}
        value={value}
        inputRef={inputRef}
        disabled={isDisabled}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onChange={onInput}
        onBlur={onBlur}
        onInvalid={onInvalid}
        endAdornment={unit ? (
          <StyledInputAdornment position="end">
            {unit}
          </StyledInputAdornment>
        )
          : undefined}
      />
      {hint && <FormHelperText>{hint}</FormHelperText>}
      {!noErrorMessage && <FormHelperTextWithStyles variant="filled" error>{hasFocus ? ' ' : error || ' '}</FormHelperTextWithStyles>}
    </FormControl>
  );
};

TextField.propTypes = TextFieldPropTypes;
TextField.defaultProps = TextFieldDefaultProps;

export default TextField;
