import React from 'react';
import PropTypes from 'prop-types';
import Container from './ErrorMessage.style';

const ErrorMessage = props => (
  <Container>{props.children}</Container>
);

ErrorMessage.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorMessage;
