import styled from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';

const MODIFIER_LABEL = {
  hasFocus: ({ theme }) => `
    color: ${theme.labelColorFocus}`,
  isInvalid: ({ theme }) => `
    color: ${theme.labelColorInvalid};`,
};

const Label = styled.label`
  all: initial;
  margin: 0 0 0.5em;
  font-family: ${props => props.theme.fontFamily};
  font-size: .875em;
  line-height: 1em;
  color: ${props => props.theme.labelColor};
  pointer-events: none;
  z-index: 1; /* at top of browser's autosuggest */

  ${applyStyleModifiers(MODIFIER_LABEL)}
`;

Label.propTypes = {
  modifiers: styleModifierPropTypes(MODIFIER_LABEL),
};


export default Label;
