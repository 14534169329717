import Styled from 'styled-components';
import RadioField from '../../molecules/RadioField/RadioField.style';

/* private variables */
const formPadding = '1em';
const flexItemPadding = '.5em';
const controlSameHeight = '3em';

export const Form = Styled.form`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: calc(${formPadding} - ${flexItemPadding});
  width: 100%;

  ${RadioField} {
    margin-bottom: .75em;
    min-height: ${controlSameHeight};
  }

`;

const Fieldset = Styled.fieldset`
  flex: 1 1 100%;
  margin: 0;
  padding: ${flexItemPadding};
  border: none;
`;
export const CreditFieldset = Styled(Fieldset)``;
export const ContactFieldset = Styled(Fieldset)``;

export const SecondDebtorFieldset = Styled.fieldset`
  margin: 1.25em 0 0 0;
  padding: 0;
  border: none;

  /* reset same height from above */
  ${RadioField} {
    min-height: auto;
  }
`;

export const Footer = Styled.div`
  padding: ${flexItemPadding};
  width: 100%;
`;

export const LegalText = Styled.p`
  font-size: .625em;
  font-weight: bold;
  line-height: 1.25em;
  text-align:center;
  color: ${props => props.theme.labelColor};
`;

export const BestOffer = Styled.div`
  display: flex;
  flex:1;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 0.75em;
  min-height: ${controlSameHeight};

  output {
    display: block;
    min-width: 6em; /* reserved space for larger numbers */
    font-size: 1.25em;
    font-weight: bold;
    line-height: 2em;
    color: ${props => props.theme.labelColorFocus};
  }

  label {
    display: block;
    font-size: 0.75em;
    line-height: 1em;
    font-weight: bold;
    color: ${props => props.theme.hintColor};
  }
`;

export const Section = Styled.div`
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:flex-end;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.5em;
    margin-bottom: .5em ;
    color: ${props => props.theme.labelColorFocus};

    & > svg {
     height: 38px
    }

    @media (min-width: 500px) {
      & > svg {
        display:none;
        visibility: hidden;
      }
    }
`;

export const Row = Styled.div`
  display:flex;
  flex:1 1;
  flex-direction:row;
  flex-wrap:wrap;
  margin-left:-0.5em;
  margin-right:-0.5em;

  > * {
    flex: 1 1 250px;
    margin-left:0.5em;
    margin-right:0.5em;
  }
`;

export const Spacer = Styled.div`
  @media (min-width: 530px) {
    visibility:hidden;
  }
`;

export const TermsWrapper = Styled.div`
  margin:0.5em;
`;

const Seal = Styled.div`
  display:flex;
  flex-direction:row;
`;

export const SealBottom = Styled(Seal)`
    justify-content:center;
    align-items:flex-start;

    margin:8px;
    & > svg {
     height: 38px
    }
    @media (max-width: 500px) {
      display:none;
      visibility: hidden;
    }
`;
