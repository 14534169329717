import styled from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_TEXTFIELD_UNIT = {
};

/**
 * @namespace
 * @class TextFieldUnit
 * @param {Object} props                       - The component properties.
 * @classdesc A (styled) `label` component.
 */
export const TextFieldUnit = styled.label`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  all: initial;
  font-family: ${props => props.theme.fontFamily};
  flex: 0 0 auto;
  font-size: .875em;
  line-height: 1em;
  padding-right:0.75em;
  color: ${props => props.theme.unitColor};
  transition: padding ${props => props.theme.animationDuration} ease-out;
  z-index: 1; /* at top of browser's autosuggest */
  pointer-events: none;

  ${applyStyleModifiers(MODIFIER_TEXTFIELD_UNIT)}
`;

TextFieldUnit.propTypes = {
  /**
   * Modifiers
   */
  modifiers: styleModifierPropTypes(MODIFIER_TEXTFIELD_UNIT),
};
