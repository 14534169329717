import styled from 'styled-components';
import { applyStyleModifiers, styleModifierPropTypes } from 'styled-components-modifiers';

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_CHECKBOXFIELD_WRAPPER = {
  isInvalid: ({ theme }) => `
    border-color: ${theme.borderColorInvalid};

    &:focus ~ ${'RadioFieldRadio'} {
      box-shadow: ${theme.shadowInvalid};
    }
  `,
  isChecked: ({ theme }) => `
    background: ${theme.checkColor};
  `,
};

/**
 * @namespace
 * @class CheckboxFieldWrapper
 * @param {Object} props                       - The component properties.
 * @classdesc A (styled) `div` component.
 */
export const CheckboxFieldWrapper = styled.div`
  all: initial;
  font-family: ${props => props.theme.fontFamily};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative; 

  &:focus {
    border-color: ${props => props.theme.borderColorHover};
    box-shadow: ${props => props.theme.shadowFocus};
  }
  
 ${props => (props.box ?
    `
  padding: 1em;
  background: #F2F3F5;
  border-radius: .125em;
  border: ${props.theme.borderWidth} solid ${props.theme.borderColor};
  ` : '')
}

  ${applyStyleModifiers(MODIFIER_CHECKBOXFIELD_WRAPPER)}
`;

CheckboxFieldWrapper.propTypes = {
  /*
  * Modifiers
  */
  modifiers: styleModifierPropTypes(MODIFIER_CHECKBOXFIELD_WRAPPER),
};
