import PropTypes from 'prop-types';

const TextFieldPropTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  inputRef: PropTypes.func,
  type: PropTypes.string,
  pattern: PropTypes.string,
  label: PropTypes.string.isRequired,
  unit: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  autoComplete: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onInput: PropTypes.func,
  onInvalid: PropTypes.func,
  onKeyDown: PropTypes.func,
  maxLength: PropTypes.number,
};

const TextFieldDefaultProps = {
  id: '',
  type: 'text',
  pattern: undefined,
  unit: '',
  hint: '',
  placeholder: ' ', // CSS hack vor :placeholder-shown selector
  value: '', // zero length value required for correct CSS behaviour
  autoComplete: 'on',
  isRequired: false,
  isDisabled: false,
  noErrorMessage: false,
  onBlur: () => { },
  onChange: () => { },
  onFocus: () => { },
  onInput: () => { },
  onInvalid: () => { },
  onKeyDown: () => { },
};

export { TextFieldPropTypes, TextFieldDefaultProps };
