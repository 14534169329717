/* eslint-disable react/no-unused-state */

import PropTypes from 'prop-types';
import React from 'react';

import FieldError from '../../atoms/FieldError/FieldError';

import FieldHint from '../../atoms/FieldHint/FieldHint';
import { OptionType } from '../../util/formHelper';

import { randomHash } from '../../util/random';

import {
  Bubble,
  Container,
  DIRECTION_HORIZONTAL as _DIRECTION_HORIZONTAL,
  DIRECTION_VERTICAL as _DIRECTION_VERTICAL,
  Input,
  Label,
  Radio,
  ResponsiveTab,
  ResponsiveTabs,
  Tab,
  Tabs,
} from './TabField.style';

export const DIRECTION_HORIZONTAL = _DIRECTION_HORIZONTAL;
export const DIRECTION_VERTICAL = _DIRECTION_VERTICAL;

class TabField extends React.PureComponent {

  /**
   * @type {Object}
   */
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    hint: PropTypes.string,
    options: PropTypes.arrayOf(OptionType).isRequired,
    value: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    direction: PropTypes.oneOf([
      DIRECTION_HORIZONTAL,
      DIRECTION_VERTICAL,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  };

  /**
   * @type {Object}
   */
  static defaultProps = {
    id: '',
    label: '',
    hint: '',
    value: '',
    required: false,
    disabled: false,
    direction: DIRECTION_HORIZONTAL,
    onChange: () => {},
    onBlur: () => {},
  };

  /**
   * @constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    const {
      value,
      disabled,
      required,
      id,
    } = this.props;

    this.state = {
      value,
      isDirty: false,
      isDisabled: disabled,
      isRequired: required,
      isValid: undefined,
      hasFocus: false,
      wasTouched: false,
      error: '',
    };

    this.id = id || randomHash();
  }

  /**
   * Adds custom validity messages directly to the input.
   *
   * @param {HTMLInputElement} input
   */
  updateCustomValidity = (input) => {
    const validityState = input.validity;

    // reset custom validity and run the validation chain again
    if (validityState.customError) return;

    let error = '';

    if (validityState.valueMissing) {
      error = 'Eine dieser Optionen müssen Sie wählen.';
    }

    input.setCustomValidity(error);
  };

  /**
   * Handles focus event on input
   *
   * @param {Event} event
   */
  handleFocus = () => {
    this.setState({
      hasFocus: true,
    });
  };

  /**
   * Handles change event on input
   *
   * @param {Event} event - event from input
   */
  handleChange = (event) => {
    const { target } = event;
    const cleanValue = target.value;

    // call external handlers before validation and before saving value to state
    this.props.onChange(event, cleanValue);

    let newState = {
      isDirty: true,
      value: target.value,
    };

    // reset custom error (base component only - don't do this in extends!)
    target.setCustomValidity('');

    this.updateCustomValidity(target);

    // do not mark errors instantly when input is used first time
    if (this.state.isValid === false || this.state.wasTouched) {
      newState = {
        ...newState,
        isValid: target.validity.valid,
        error: target.validationMessage,
      };
    }

    this.setState(newState);
  };

  /**
   * Handles blur event on input
   *
   * @param {Event} event - event from input
   */
  handleBlur = (event) => {
    const { target } = event;

    this.updateCustomValidity(event.target);

    // call external handlers before validation and before saving value to state
    this.props.onBlur(event);

    this.setState({
      wasTouched: true,
      hasFocus: false,
      isValid: target.validity.valid,
      error: target.validationMessage,
    });
  };

  /**
   * Handles invalid event on input
   * Will get triggered when calling 'checkValidity()' via input or form
   *
   * @param {Event} event
   */
  handleInvalid = (event) => {
    event.preventDefault();
    const { target } = event;

    this.setState({
      isValid: target.validity.valid,
      error: target.validationMessage,
    });
  };

  /**
   * @returns {JSX}
   */
  render() {
    const {
      id,
      props: {
        name,
        label,
        options,
        hint,
        direction,
      },
      state: {
        value,
        isRequired,
        isDisabled,
        isValid,
        hasFocus,
        error,
      },
    } = this;

    const TabsComponent = direction === DIRECTION_VERTICAL ? Tabs : ResponsiveTabs;
    const TabComponent = direction === DIRECTION_VERTICAL ? Tab : ResponsiveTab;

    return (
      <Container>
        {label && (
          <Label {...{ isDisabled, isValid, hasFocus }}>{label}</Label>
        )}
        <TabsComponent optionsDirection={direction}>
          {options.map((item, idx) => {
            const optionId = id + (idx ? '-' + idx : ''); // add index to all but not first input
            const optionLabel = item.label;
            const optionValue = item.value;
            const OptionIcon = item.icon;

            return (
              <div>
                <Input
                  id={optionId}
                  type="radio"
                  name={name}
                  value={optionValue}
                  checked={optionValue === value}
                  required={isRequired}
                  disabled={isDisabled}
                  onFocus={this.handleFocus}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  onInvalid={this.handleInvalid}
                />
                <TabComponent htmlFor={optionId}>
                  {OptionIcon ? <OptionIcon /> : (
                    <Radio
                      htmlFor={optionId}
                      {...{
                        isDisabled,
                        hasFocus,
                        isValid,
                      }}
                    >
                      <Bubble />
                    </Radio>
                  )}
                  {optionLabel}
                </TabComponent>
              </div>
            );
          })}
        </TabsComponent>
        {error && (
          <FieldError htmlFor={id}>{error}</FieldError>
        )}
        {hint && (
          <FieldHint htmlFor={id}>{hint}</FieldHint>
        )}
      </Container>
    );
  }

}

export default TabField;
