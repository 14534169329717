import styled from 'styled-components';
import { applyStyleModifiers, styleModifierPropTypes } from 'styled-components-modifiers';

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_CHECKBOXFIELD_LABEL = {
  isInvalid: ({ theme }) => `
    color: ${theme.labelColorInvalid};

    & * {
      color: inherit !important;
    }
  `,
  hasFocus: ({ theme }) => `
  color: ${theme.labelColorFocus};`,
};

/**
 * @namespace
 * @class CheckboxFieldLabel
 * @param {Object} props                       - The component properties.
 * @classdesc A (styled) `label` component.
 */
export const CheckboxFieldLabel = styled.label`
  all: initial;
  font-family: ${props => props.theme.fontFamily};
  font-size: .875em;
  line-height: 1.25em;
  cursor:pointer;

  ${applyStyleModifiers(MODIFIER_CHECKBOXFIELD_LABEL)}
`;

CheckboxFieldLabel.propTypes = {
  /*
  * Modifiers
  */
  modifiers: styleModifierPropTypes(MODIFIER_CHECKBOXFIELD_LABEL),
};
