import Styled from 'styled-components';

export default Styled.button`
  padding: 10px 16px;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.3em;
  font-family: ${props => props.theme.fontFamily};

  color: ${props => props.theme.buttonTextColor};
  text-align: center;
  background-color: ${props => props.theme.buttonBackgroundColor};
  border:none;
  border-radius: 4px;
  cursor: pointer;

  transition: all ${props => props.theme.buttonAnimationDuration} ease-in;

  &:hover {
   background-color: #204D79;
  }

  &:disabled {
    color: #95A1AB;
    background-color: ${props => props.theme.buttonBackgroundColorDisabled};
  }
`;
