import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import SelectFieldLogic from './SelectFieldLogic';
import FFGLook from './ffg/SelectField';
import ScoutLook from './scout/SelectField';

// TODO this could not be the way to create the most smallest bundle. How to just include a certain look and feel per bundle?
const SelectField = (props) => {
  let SelectFieldClass = FFGLook;

  switch (props.theme.lookAndFeel) {
    case 'Scout':
      SelectFieldClass = ScoutLook;
      break;
    default:
      SelectFieldClass = FFGLook;
  }

  return <SelectFieldLogic {...props} componentClass={SelectFieldClass} />;
};

SelectField.propTypes = {
  theme: PropTypes.shape({
    lookAndFeel: PropTypes.string,
  }).isRequired,
};

export default withTheme(SelectField);
