import React from 'react';
import PropTypes from 'prop-types';

import Container from './Container.style';
import Label from './Label.style';
import Border from './Border.style';
import Error from './Error.style';
import Hint from './Hint.style';

const Field = ({
  htmlFor, label, hasFocus, isValid, error, hint,
  children,
}) => (
  <Container>
    <Label
      htmlFor={htmlFor}
      modifiers={[
        ...(hasFocus ? ['hasFocus'] : []),
        ...(isValid === false ? ['isInvalid'] : []),
      ]}
    >
      {label}
    </Label>

    <Border
      modifiers={[
        ...(hasFocus ? ['hasFocus'] : []),
        ...(isValid === false ? ['isInvalid'] : []),
        ...(isValid === false && hasFocus ? ['isInValidAndHasFocus'] : []),
        ...(isValid === true && hasFocus ? ['isValidAndHasFocus'] : []),
      ]}
    >
      {children}
    </Border>

    {error && <Error>{error}</Error>}
    {hint && <Hint>{hint}</Hint>}
  </Container>
);

Field.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string])).isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  hasFocus: PropTypes.bool.isRequired,
  isValid: PropTypes.bool,
};

Field.defaultProps = {
  isValid: undefined,
};

export default Field;
