import { injectGlobal } from 'styled-components';
import { theme } from './theme';

/**
 * @module theme:theme-helper
 * @description This module provides helper functions for the theme loading context.
 */

/**
 * The regex to get custom properties from a string.
 * @example <caption>Example usage of method1.</caption>
 * // returns array with e.g. match[1] = 'colorPrimary' and match[2] = '#41f498'
 * match = customPropertiesRegex.exec('
 * {
 *   --colorPrimary: #41f498;
 *   --colorSecondary: #dff441;
 *   --colorBaseDark40: #42f456;
 *  }');
 * @type {RegExp}
 */
const customPropertiesRegex = /([\w-]+?)\s*:\s*([\s\S]+?)\s*;/g;

/**
 * Converts a CSS String to a JavaScript object.
 *
 * @param {string} css - The css string to parse
 * @returns {Object} The css string as object
 */
const convertCSSString2JSObject = (css) => {
  const properties = {};
  let match = [];
  do {
    match = customPropertiesRegex.exec(css);
    try {
      if (match && !match[2]) throw new Error(`Value of ${match[1]} missing.`);
      if (match) properties[match[1]] = match[2].trim();
    }
    catch (error) {
      console.error(error.message);
    }
  } while (match);

  return properties;
};

/**
 * Injects the custom properties CSS file in the head.
 *
 * @param {string} customPropertiesUrl - The CSS customPropertiesUrl
 * @param {Object} document - The document object.
 */
const writeCSSClassInHead = (customPropertiesUrl, document) => new Promise((resolve, reject) => {
  try {
    const { head } = document;
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = customPropertiesUrl;
    link.media = 'all';

    link.addEventListener('load', () => {
      resolve();
    });

    link.addEventListener('error', (error) => {
      reject(error);
    });

    head.appendChild(link);
  }
  catch (error) {
    reject(error);
  }
});


/**
 *
 * Returns the correct syntax for the CSS property in dependence if the browser
 * supports custom properties or not.
 *
 * @param {string} cssProperty        - The CSS property like --colorPrimary.
 * @param {string} ffgVar             - The Finanzcheck color.
 * @param {Object} customProperties   - The CSS custom property from the CSS file.
 * @returns {string} The CSS property.
 */
const getSchemaStyles = (cssProperty, ffgVar, customProperties) => (
  customProperties ? (customProperties[`--${cssProperty}`] || ffgVar) : `var(--${cssProperty}, ${ffgVar})`
);

/**
 * Returns the ui-kit theme with the correct properties syntax.
 *
 * @param {Object} customProperties   - The custom properties from the CSS file.
 * @returns {Object} The parsed and by custom properties replaced theme.
 */
const getParsedSchema = (customProperties) => {
  const parsedTheme = {};
  Object.entries(theme).forEach((value) => {
    parsedTheme[value[0]] = getSchemaStyles(value[0], value[1], customProperties);
  });
  return parsedTheme;
};

/**
 *  Fetches the custom properties css file and replaces the theme vars with this
 *  custom properties.
 *
 * @param {string} customPropertiesUrl - The URL for the custom properties css file.
 * @returns {Object} The ui-kit theme.
 */
async function getSchemaByCustomPropertiesUrl(customPropertiesUrl) {
  let css;
  try {
    css = await fetch(customPropertiesUrl);
    if (!css.ok) throw new Error(css.status);
  }
  catch (error) {
    console.error(`${customPropertiesUrl} - is not correct or not reachable.`, error);

    return getParsedSchema({});
  }

  const cssText = await css.text();
  const cleanedStyle = cssText.substring(cssText.indexOf('{'), cssText.length);
  const customProperties = convertCSSString2JSObject(cleanedStyle);

  return getParsedSchema(customProperties);
}

/**
 * Tests if the browser supports custom properties. If true it writes the custom properties file
 * in the head and returns the default schema with custom properties.
 * Otherwise it gets the custom properties file via javascript and replaces all css properties
 * with custom properties inside the theme schema.
 *
 * @param {string} customPropertiesUrl - The custom propertes css file URL.
 * @param {Object} window - The window object.
 * @param {Object} document - The document object.
 * @returns {Promise<*>} The ui-kit theme.
 */
async function getPreparedSchema(customPropertiesUrl, window, document) {
  if (window.CSS && window.CSS.supports('color', 'var(--primary)')) {
    try {
      await writeCSSClassInHead(customPropertiesUrl, document);
    }
    catch (error) {
      console.error(`${customPropertiesUrl} - is not correct or not reachable.`, error);
    }

    return getParsedSchema();
  }

  return getSchemaByCustomPropertiesUrl(customPropertiesUrl);
}

/**
 * Uses the injectGobal function from styled components to inject
 * global styles.
 *
 * @param {Object} preparedTheme - The prepared ui-kit theme
 */
const injectGlobalStyles = preparedTheme => (
  injectGlobal`
    html {
      font-size: ${preparedTheme.fontSize};
      font-family: ${preparedTheme.fontFamily};
    }
  `
);

/**
 * Tests if the CSS file which includes custom properties is available and
 * replaces the FFG vars from the theme with this custom properties.
 *
 * @param {string} customPropertiesUrl - The custom propertes css file URL.
 * @param {Object} window - The window object.
 * @param {Object} document - The document object.
 * @param {boolean} enableGlobalStyles - Whether the global styles are enabled in the app or not..
 * @returns {Object} The prepared ui-kit theme.
 */
export const getTheme = (customPropertiesUrl, window, document, enableGlobalStyles) => {
  let preparedTheme = theme;

  if (customPropertiesUrl) {
    preparedTheme = getPreparedSchema(customPropertiesUrl, window, document);
  }

  if (enableGlobalStyles) {
    injectGlobalStyles(preparedTheme);
  }

  return preparedTheme;
};
