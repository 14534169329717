import React from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import PixelContainer from './PaasPixel.style';

class PaasPixel extends React.Component {

  state = {
    wasSeen: false,
  };

  render() {
    const {
      state: { wasSeen },
      props: { url },
    } = this;
    return (
      <VisibilitySensor
        onChange={(isVisible) => {
          if (isVisible) {
            this.setState({ wasSeen: isVisible });
          }
        }}
        active={!wasSeen}
      >
        <PixelContainer>
          {wasSeen ? <img src={url} alt="" /> : null}
        </PixelContainer>
      </VisibilitySensor>
    );
  }

}

PaasPixel.propTypes = {
  url: PropTypes.instanceOf(URL).isRequired,
};

export default PaasPixel;
