import styled from 'styled-components';

/**
 * @namespace
 * @class TextFieldError
 * @param {Object} props                       - The component properties.
 * @classdesc A (styled) `label` component.
 */
export const TextFieldError = styled.label`
  all: initial;
  font-family: ${props => props.theme.fontFamily};
  display: block;
  margin: .5em ${props => props.theme.paddingVertical};
  font-size: .75em;
  line-height: 1em;
  color: ${props => props.theme.colorInvalid};
  pointer-events: none;
`;
