import React from 'react';
import PropTypes from 'prop-types';

import StyledButton from './Button.style';

class Button extends React.PureComponent {

  /**
   * @type {Object}
   */
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
  };

  /**
   * @type {Object}
   */
  static defaultProps = {
    id: '',
    type: 'button',
    name: '',
    value: '',
    disabled: false,
    style: {},
  };

  /**
   * @returns {JSX}
   */
  render() {
    const {
      id,
      name,
      type,
      label,
      value,
      disabled,
      style,
    } = this.props;

    return (
      <StyledButton
        id={id}
        name={name}
        type={type}
        value={value}
        disabled={disabled}
        style={style}
      >
        {label}
      </StyledButton>
    );
  }

}

export default Button;
