import styled from 'styled-components';
import { WIDGET_ROOT_TAG } from '../../../Ids';

export const TextInput = styled.input`
   #${WIDGET_ROOT_TAG} & {
    all: initial;
    flex-grow: 1;
    flex-shrink: 0;

    margin: ${props => props.theme.borderWidth}; /* border will shine through from behind */
    padding: 1.875em ${props => props.theme.iconWidth} .625em ${props => props.theme.paddingVertical};
    font-family: ${props => props.theme.fontFamily};
    font-size: .875em;
    line-height: 1em;
    color: ${props => props.theme.color};
    background: transparent;
    border: none;
    box-shadow: none;
    outline: unset;
    z-index: 1;
  }

  #${WIDGET_ROOT_TAG} &::-ms-clear {
    display: none;
  }

  #${WIDGET_ROOT_TAG} &::placeholder {
    color: ${props => props.theme.placeholderColor};
    opacity: 0;
    transition: opacity ${props => props.theme.animationDuration};
  }

  #${WIDGET_ROOT_TAG} &:focus::placeholder {
    opacity: 1;
  }

  #${WIDGET_ROOT_TAG} &:-webkit-autofill,
  #${WIDGET_ROOT_TAG} &:-webkit-autofill:hover,
  #${WIDGET_ROOT_TAG} &:-webkit-autofill:focus
  {
    margin: 1px;
    border-radius: 2px
  }

`;
