import styled from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_TEXTFIELD_UNIT = {
  hasFocus: ({ theme }) => `
    padding-right: ${theme.iconWidth};`,
  isValid: ({ theme }) => `
    padding-right: ${theme.iconWidth};
  `,
  isInvalid: ({ theme }) => `
    padding-right: ${theme.iconWidth};`,
};

/**
 * @namespace
 * @class TextFieldUnit
 * @param {Object} props                       - The component properties.
 * @classdesc A (styled) `label` component.
 */
export const TextFieldUnit = styled.label`
  all: initial;
  font-family: ${props => props.theme.fontFamily};
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: calc(${props => props.theme.iconWidth} * -1);
  padding: 0 ${props => props.theme.paddingVertical};
  font-size: .875em;
  line-height: 1em;
  color: ${props => props.theme.unitColor};
  transition: padding ${props => props.theme.animationDuration} ease-out;
  z-index: 1; /* at top of browser's autosuggest */
  pointer-events: none;

  ${applyStyleModifiers(MODIFIER_TEXTFIELD_UNIT)}
`;

TextFieldUnit.propTypes = {
  /**
   * Modifiers
   */
  modifiers: styleModifierPropTypes(MODIFIER_TEXTFIELD_UNIT),
};
