import React from 'react';
import PropTypes from 'prop-types';

import { randomHash } from '../../util/random';
import { OptionType } from '../../util/formHelper';


class SelectFieldLogic extends React.PureComponent {

  /**
   * @type {Object}
   */
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    componentClass: PropTypes.func.isRequired,
    hint: PropTypes.string,
    options: PropTypes.arrayOf(OptionType).isRequired,
    value: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  };

  /**
   * @type {Object}
   */
  static defaultProps = {
    id: '',
    hint: '',
    value: '',
    required: false,
    disabled: false,
    onBlur: () => {},
    onChange: () => {},
  };

  /**
   * @constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    const {
      id,
      required,
      disabled,
    } = this.props;

    this.state = {
      isRequired: required,
      isDisabled: disabled,
      // isDirty: false,
      isValid: undefined,
      hasFocus: false,
      wasTouched: false,
      error: '',
    };

    this.id = id || randomHash();
    this.input = null;
  }

  /**
   * Handles focus event on input
   */
  handleFocus = () => {
    this.setState({
      hasFocus: true,
    });
  };

  /**
   * Handles change event on input
   *
   * @param {Event} event - event from input
   */
  handleChange = (event) => {
    const { target } = event;

    // call external handlers before validation and before saving value to state
    this.props.onChange(event, target.value);

    let newState = {
      value: target.value,
      isDirty: true,
    };

    // do not mark errors instantly when input is used first time
    if (this.state.isValid === false || this.state.wasTouched) {
      newState = {
        ...newState,
        isValid: target.validity.valid,
        error: target.validationMessage,
      };
    }

    this.setState(newState);
  };

  /**
   * Handles blur event on input
   *
   * @param {Event} event - event from input
   */
  handleBlur = (event) => {
    const { target } = event;

    // call external handlers before validation
    this.props.onBlur(event);

    this.setState({
      wasTouched: true,
      hasFocus: false,
      isValid: target.validity.valid,
      error: target.validationMessage,
    });
  };

  /**
   * Handles invalid event on input
   * Will get triggered when calling 'checkValidity()' via input or form
   *
   * @param {Event} event
   */
  handleInvalid = (event) => {
    event.preventDefault(); // prevent browser's error bubble
    const { target } = event;

    this.setState({
      isValid: target.validity.valid,
      error: target.validationMessage,
    });
  };

  /**
   * Render function
   *
   * @returns {JSX} - returns the Select field
   */
  render() {
    const { componentClass: SelectField } = this.props;
    return (
      <SelectField
        {...this.props}
        {...this.state}
        inputRef={(node) => { this.inputElement = node; }}
        id={this.id}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onInvalid={this.handleInvalid}
      />
    );
  }

}

export default SelectFieldLogic;
