import React from 'react';
import styled from 'styled-components';
import { applyStyleModifiers, styleModifierPropTypes } from 'styled-components-modifiers';

const IconDropdownComponent = props => (
  <i {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line max-len
        d="M16.236447,8.9427827 L12,13.17 L7.76355297,8.9427827 C7.56822872,8.747884 7.25194355,8.74805645 7.05683195,8.94316805 L6.35355339,9.64644661 C6.15829124,9.84170876 6.15829124,10.1582912 6.35355339,10.3535534 L11.6464466,15.6464466 C11.8417088,15.8417088 12.1582912,15.8417088 12.3535534,15.6464466 L17.6464466,10.3535534 C17.8417088,10.1582912 17.8417088,9.84170876 17.6464466,9.64644661 L16.943168,8.94316805 C16.7480565,8.74805645 16.4317713,8.747884 16.236447,8.9427827 Z"
        id="path-1"
      />
    </svg>
  </i>
);

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_ICON_DROPDOWN = {
  hasFocus: ({ theme }) => `color: ${theme.labelColorFocus};`,
  isInvalid: ({ theme }) => `color: ${theme.labelColorInvalid};`,
};

/**
 * @namespace
 * @class SelectFieldIconDropdown
 * @param {Object} props                       - The component properties.
 * @classdesc A custom (styled) `IconDropdownComponent` component.
 */
export const SelectFieldIconDropdown = styled(IconDropdownComponent)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.theme.iconWidth};
  color: ${props => props.theme.labelColor};
  z-index: 1; /* at top of browser's autosuggest */
  pointer-events: none;

  ${applyStyleModifiers(MODIFIER_ICON_DROPDOWN)}
`;

SelectFieldIconDropdown.propTypes = {
  /**
   * Modifiers
   */
  modifiers: styleModifierPropTypes(MODIFIER_ICON_DROPDOWN),
};
