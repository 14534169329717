/* eslint-disable no-nested-ternary */
import { formatAmount } from './numberFormatter';

const buildTwoThirdLegalTextString = ({
  netAmountTwoThird,
  effectiveInterestRateTwoThird,
  nominalInterestRateTwoThird,
  monthlyRateTwoThird,
  totalAmountTwoThird,
  bankName,
  term,
}) => {
  if (bankName === 'Ofina') {
    const isNegativeInterestRate = effectiveInterestRateTwoThird < 0;

    return [
      isNegativeInterestRate ? '*Alle Kunden erhalten:' : '*2/3 aller Kunden erhalten:',
      netAmountTwoThird !== undefined ? (
        isNegativeInterestRate ?
          `bei ${formatAmount(netAmountTwoThird, '€', 2)} Netto-Darlehensbetrag,` :
          `Netto-Darlehensbetrag ${formatAmount(netAmountTwoThird, '€', 2)},`
      ) : null,
      `${term} Monate Laufzeit,`,
      effectiveInterestRateTwoThird !== undefined ? `effektiver Jahreszins ${formatAmount(effectiveInterestRateTwoThird, '%', 2)},` : null,
      nominalInterestRateTwoThird !== undefined ? (
        isNegativeInterestRate ?
          `${formatAmount(nominalInterestRateTwoThird, '%', 2)} fester Sollzins p.a.,` :
          `${formatAmount(nominalInterestRateTwoThird, '%', 2)} p.a. gebundenerm Sollzins,`
      ) : null,
      monthlyRateTwoThird !== undefined ? `${term} mtl. Raten zu je ${formatAmount(monthlyRateTwoThird, '€', 2)},` : null,
      totalAmountTwoThird !== undefined ? `${formatAmount(totalAmountTwoThird, '€', 2)} Gesamtbetrag,` : null,
      'Solaris SE, Cuvrystr. 53, 10997 Berlin',
    ].filter(e => e !== null && e !== undefined).join(' ') + '.';
  }

  return [
    '*2/3 aller Kunden erhalten:',
    netAmountTwoThird !== undefined ? `Netto-Darlehensbetrag ${formatAmount(netAmountTwoThird, '€', 2)},` : null,
    effectiveInterestRateTwoThird !== undefined ? `effektiver Jahreszins ${formatAmount(effectiveInterestRateTwoThird, '%', 2)},` : null,
    nominalInterestRateTwoThird !== undefined ? `fester Sollzins p.a. ${formatAmount(nominalInterestRateTwoThird, '%', 2)},` : null,
    monthlyRateTwoThird !== undefined ? `monatliche Rate ${formatAmount(monthlyRateTwoThird, '€', 2)},` : null,
    totalAmountTwoThird !== undefined ? `Gesamtbetrag ${formatAmount(totalAmountTwoThird, '€', 2)}` + (bankName ? ',' : '') : null,
    bankName || null,
  ].filter(e => e !== null && e !== undefined).join(' ') + '.';
};

export default buildTwoThirdLegalTextString;
