import styled from 'styled-components';

/**
 * @namespace
 * @class CheckboxFieldInput
 * @classdesc A (styled) `input` component.
 */
export const CheckboxFieldInput = styled.input`
  all: initial;
  position: absolute;
  left: -100vw;
  width: 0;
  height: 0;
  background: transparent;
  border: none;
  z-index: -1;
`;
