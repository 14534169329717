import React from 'react';


import Field from '../../Field';
import { SelectFieldInput } from './selectFieldInput';
import { SelectFieldIconDropdown } from './selectFieldIconDropdown';
import { SelectFieldDefaultProps, SelectFieldPropTypes } from '../PropTypes';

class SelectField extends React.PureComponent {

  static propTypes = SelectFieldPropTypes;

  static defaultProps = SelectFieldDefaultProps;

  render() {
    return (
      <Field
        htmlFor={this.props.id}
        label={this.props.label}
        error={this.props.error}
        hint={this.props.hint}
        hasFocus={this.props.hasFocus}
        isValid={this.props.isValid}
      >
        <SelectFieldInput
          ref={this.props.inputRef}
          id={this.props.id}
          name={this.props.name}
          required={this.props.isRequired}
          disabled={this.props.isDisabled}
          onBlur={this.props.onBlur}
          onChange={this.props.onChange}
          onFocus={this.props.onFocus}
          onInvalid={this.props.onInvalid}
        >
          { this.props.options.map((option, idx) => {
            const optionId = this.props.id + '-' + idx;

            return (
              <option key={optionId} value={option.value} selected={this.props.value === option.value}>{option.label}</option>
            );
          })}
        </SelectFieldInput>
        <SelectFieldIconDropdown
          modifiers={[
            ...(this.props.hasFocus ? ['hasFocus'] : []),
            ...(this.props.isValid === false ? ['isInvalid'] : []),
          ]}
        />
      </Field>
    );
  }

}

export default SelectField;
