/**
 * see webpackDevServer.config.js for local server's mock path config
 */

/**
 * CAFE
 */
export const MOCK_CAFE_BASE_URL = 'http://localhost:3000';
export const MOCK_CAFE_PRIVACY_URL = MOCK_CAFE_BASE_URL + '/flow/privacy';
export const MOCK_CAFE_TERMS_URL = MOCK_CAFE_BASE_URL + '/flow/terms';
export const MOCK_CAFE_INFO_OBLIGATION_URL = MOCK_CAFE_BASE_URL + '/flow/vvi';

/**
 * PAAS
 */
export const MOCK_PAAS_BASE_URL = 'http://localhost:3000';
export const MOCK_PAAS_LOG_URL = MOCK_PAAS_BASE_URL + '/paas/v1/log/';
export const MOCK_PAAS_PREFILL_URL = MOCK_PAAS_BASE_URL + '/paas/v1/prefill/';
export const MOCK_PAAS_PRODUCTS_URL = MOCK_PAAS_BASE_URL + '/paas/v1/products/';
