import Styled from 'styled-components';

export default Styled.label`
  display: block;
  margin: 0.5em 0;
  font-family: ${props => props.theme.fontFamily};
  font-size: 0.75em;
  line-height: 1em;
  color: ${props => props.theme.hintColor};
  pointer-events: none;
`;
