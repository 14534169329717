import Styled, { keyframes } from 'styled-components';

import {
  VIEWPORT_XXS,
  VIEWPORT_XS_MAX,
} from '../../util/mediaQueries';


const CloseButtonSize = '2.5em';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const popIn = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(.7, .7, 1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

export const CloseButton = Styled.button`
  z-index: 10001;
  position: fixed;
  right: .75em;
  top: .75em;
  padding: 0;
  width: ${CloseButtonSize};
  height: ${CloseButtonSize};
  font-size: 1.5em;
  line-height: 0;
  color: #95A1AB;
  background-color: transparent;
  border: none;
  border-radius: calc(${CloseButtonSize} / 2);
  cursor: pointer;
  transition: color .3s, background-color .3s;

  &:hover {
    background-color: #E5E8EA;
  }

  &:focus {
    outline: none;
  }

  @media ${VIEWPORT_XS_MAX} {
    background: #fff;
    box-shadow: 0 .25em .5em 0 #00000033;
  }
`;

export const Layer = Styled.div`
  z-index: 10000;
  display: block;
  position: absolute;
  top: 0;
  bottom: auto;
  margin:
    calc(${CloseButtonSize})
    0
  ;
  padding: 2em 2em 3em;
  width: auto;
  height: auto;
  max-width: calc(100vw - ${CloseButtonSize} * 4);
  background: #FFFFFF;
  overflow: hidden;
  border-radius: .125em;
  box-shadow: 0 .25em 2em 0 rgba(0, 0, 0, 0.33);

  /* add some animation start delay to prevent position hopping in iOS Safari */
  animation: ${popIn} .3s cubic-bezier(0.075, 0.820, 0.165, 1.000) 10ms;
  animation-fill-mode: backwards;

  @media ${VIEWPORT_XS_MAX} {
    margin: 0;
    max-width: 100%;
  }

  ${props => props.addPadding === 'horizontal' && `
    padding: 0 2em;

    @media ${VIEWPORT_XXS} {
      padding: 0 .5em;
    }
  `}

  ${props => props.addPadding === 'vertical' && `
    padding: 2em 0;

    @media ${VIEWPORT_XXS} {
      padding: .5em 0;
    }
  `}

  ${props => props.addPadding === true && `
    padding: 2em;

    @media ${VIEWPORT_XXS} {
      padding-right: .5em;
      padding-left: .5em;
    }
  `}
`;

export const Content = Styled.div`
  position: relative;
`;

export const Backdrop = Styled.div`
  z-index: 9999;
  position: fixed;

  /* add overscan to prevent ugly effects when resizing viewport */
  top: -33vh;
  bottom: -33vh;
  left: -33vw;
  right: -33vw;

  display: block;
  background: rgba(255, 255, 255, .8);
  animation: ${fadeIn} .3s ease-out;
`;
