import styled from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_TEXTFIELD_BORDER = {
  isValid: ({ theme }) => `
    border-color: ${theme.borderColorValid};`,
  isInvalid: ({ theme }) => `
    border-color: ${theme.borderColorInvalid};`,
  isInValidAndHasFocus: ({ theme }) => `
    box-shadow: ${theme.shadowInvalid};`,
  isValidAndHasFocus: ({ theme }) => `
    border-color: ${theme.borderColorValid};
    box-shadow: ${theme.shadowValid};`,
  hasFocus: ({ theme }) => `
    border-color: ${theme.borderColorFocus};
    box-shadow: ${theme.shadowFocus};`,
};

/**
 * @namespace
 * @class TextFieldBorder
 * @param {Object} props                       - The component properties.
 * @classdesc A (styled) `Span` component.
 */
export const TextFieldBorder = styled.span`
  all: initial;
  font-size: 0.875em;
  border: ${props => props.theme.borderWidth} solid
    ${props => props.theme.borderColor};
  border-radius: 0.125em;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: ${props => props.theme.shadow};
  transition: box-shadow ${props => props.theme.animationDuration} ease-out;
  z-index: 0;

  ${applyStyleModifiers(MODIFIER_TEXTFIELD_BORDER)}
`;

TextFieldBorder.propTypes = {
  /**
   * Modifiers
   */
  modifiers: styleModifierPropTypes(MODIFIER_TEXTFIELD_BORDER),
};
