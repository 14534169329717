import styled from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';

const MODIFIER_HINT = {
  isInvalid: () => 'display: none;',
};

const Hint = styled.label`
  all: initial;
  display: block;
  margin: 8px 0 0;
  font-size: .75em;
  color: ${props => props.theme.hintColor};
  pointer-events: none;

  ${applyStyleModifiers(MODIFIER_HINT)}
`;

Hint.propTypes = {
  modifiers: styleModifierPropTypes(MODIFIER_HINT),
};

export default Hint;
