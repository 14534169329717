import styled from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_SELECTFIELD_LABEL = {
  isInvalid: ({ theme }) => `
  color: ${theme.labelColorInvalid};`,
  hasFocus: ({ theme }) => `
  color: ${theme.labelColorFocus};`,
};

/**
 * @namespace
 * @class SelectFieldLabel
 * @classdesc A (styled) `label` component.
 * @param {Object} props                       - The component properties.
 */
export const SelectFieldLabel = styled.label`
  all: initial;
  font-family: ${props => props.theme.fontFamily};
  position: absolute;
  top: .75em;
  left: calc(${props => props.theme.paddingVertical} + ${props => props.theme.borderWidth});
  margin-right: ${props => props.theme.paddingVertical};
  font-size: .875em;
  line-height: 1em;
  color: ${props => props.theme.labelColor};
  transform-origin: 0 0;
  transform: scale3d(.85, .85, 1);
  pointer-events: none;
  z-index: 1; /* at top of browser's autosuggest */

  ${applyStyleModifiers(MODIFIER_SELECTFIELD_LABEL)}
`;

SelectFieldLabel.propTypes = {
  /**
   * Modifiers
   */
  modifiers: styleModifierPropTypes(MODIFIER_SELECTFIELD_LABEL),
};
