import React from 'react';
import styled from 'styled-components';

import IconValid from '../../../assets/icons/valid.svg';

const IconValidComponent = props => (
  <i {...props}>
    <IconValid />
  </i>
);


export const TextFieldIconValid = styled(IconValidComponent)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.theme.iconWidth};
  color: ${props => props.theme.borderColorValid};
  opacity: 0;
  visibility: hidden; /* shouldn't be clickable while invisible */
  transform: scale3d(.7, .7, 1);
  transition:
    margin ${props => props.theme.animationDuration} ease-out,
    transform ${props => props.theme.animationDuration} ease-out,
    opacity ${props => props.theme.animationDuration} ease-out,
    visibility ${props => props.theme.animationDuration} linear;
  z-index: 1; /* at top of browser's autosuggest */
  ${props => !props.hasFocus && props.isValid === true && `
    margin-left: calc(${props.theme.paddingVertical} * -1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
    visibility: visible;
    transition:
      margin ${props.theme.animationDuration} ease-out,
      transform ${props.theme.animationDuration} ${props.theme.animationDuration} ease-out,
      opacity ${props.theme.animationDuration} ${props.theme.animationDuration} ease-out,
      visibility ${props.theme.animationDuration} ${props.theme.animationDuration} linear
    ;
  `}
`;
