import styled from 'styled-components';

export const TextInput = styled.input`
  all: initial;
  flex-grow: 1;
  flex-shrink: 0;
 
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.color};
  font-size: .875em;
  line-height: 1em;
  
  padding:0.75em;
  
  background: transparent;
  border: none;
  box-shadow: none;
  outline: unset;
  z-index: 1;

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: ${props => props.theme.placeholderColor};
    opacity: 0;
    transition: opacity ${props => props.theme.animationDuration};
  }

  &:focus::placeholder {
    opacity: 1;
  }
  
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus
  {
    border-radius: 4px;
  }
`;
