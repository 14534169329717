import styled from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';
import { WIDGET_ROOT_TAG } from '../../../Ids';

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_SELECTFIELD_INPUT = {
  hasFocus: ({ theme }) => `
    border-color: ${theme.borderColorFocus};
    box-shadow: ${theme.shadowFocus};
  `,
  isRequiredAndisValidAndHasFocus: ({ theme }) => `
    border-color: ${theme.borderColorValid} !important;
    box-shadow: ${theme.shadowValid} !important;
  `,
  isInvalid: ({ theme }) => `
    border-color: ${theme.borderColorInvalid};

    &:hover {
      border-color: ${theme.borderColorInvalid};
    }
  `,
  isValid: ({ theme }) => `
    border-color: ${theme.borderColorValid};
    &:hover {
      border-color: ${theme.borderColorValid};
    }
  `,
  isInvalidAndHasFocus: ({ theme }) => `
    box-shadow: ${theme.shadowInvalid};
  `,
};

/**
 * @namespace
 * @class SelectFieldInput
 * @classdesc A (styled) `select` component.
 * @param {Object} props                       - The component properties.
 */
export const SelectFieldInput = styled.select`
  #${WIDGET_ROOT_TAG} & {
    all: initial;
    flex-grow: 1;
    flex-shrink: 0;
    background-color: green;
    padding: 1.875em ${props => props.theme.iconWidth} 0.625em
      ${props => props.theme.paddingVertical};
    font-family: ${props => props.theme.fontFamily};
    font-size: 0.875em;
    line-height: 1.25em;
    color: ${props => props.theme.color};
    background-color: ${props => props.theme.backgroundColor};
    outline: unset;
    box-shadow: ${props => props.theme.shadow};
    transition: box-shadow ${props => props.theme.animationDuration} ease-out;
    z-index: 1;
    appearance: none;
    border: ${props => props.theme.borderWidth} solid
      ${props => props.theme.borderColor};
    border-radius: 0.125em;
    &::-ms-expand {
      display: none;
    }

    &:hover {
      border-color: ${props => props.theme.borderColorHover};
    }

    ${applyStyleModifiers(MODIFIER_SELECTFIELD_INPUT)}
  }
`;

SelectFieldInput.propTypes = {
  /**
   * Modifiers
   */
  modifiers: styleModifierPropTypes(MODIFIER_SELECTFIELD_INPUT),
};
