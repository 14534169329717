import PropTypes from 'prop-types';
import React from 'react';
import { App as StyledApp, LoadingOverlay } from './App.style';
import CafeFrame from './atoms/CafeFrame/CafeFrame';
import LoadingSpinner from './atoms/LoadingSpinner/LoadingSpinner';
import PaasPixel from './atoms/PaasPixel/PaasPixel';
import URLFrame from './atoms/URLFrame';
import AMPIdentifier from './contexts/AMPIdentifier';
import CafeHeader from './molecules/CafeHeader/CafeHeader';
import Modal from './molecules/Modal/Modal';
import ModalStack from './molecules/Modal/ModalStack';
import ShowModalContext from './molecules/Modal/ShowModalContext';
import { widgetRoot } from './setup';
import deepDefaultProps from './util/deepDefaultProps';
import { MOCK_PAAS_LOG_URL, MOCK_PAAS_PREFILL_URL } from './util/mockData';
import initSessionStorage, { getSessionStorage } from './util/session/sessionHandler';
import './App.css';

class App extends React.PureComponent {

  static propTypes = {
    affiliateData: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  constructor(props) {
    super(props);

    // TODO is there any situation where there is NO window?!
    if (window) {
      initSessionStorage();
    }

    /** @type {String} */
    this.advertisementId = props.affiliateData.advertisementId;
    this.subId = props.affiliateData.subId;

    /** @type {URL} */
    this.submitUrl = new URL(
      process.env.REACT_APP_PAAS_PREFILL_URL || MOCK_PAAS_PREFILL_URL,
    );
    /** @type {URL} */
    this.pixelUrl = new URL(
      process.env.REACT_APP_PAAS_LOG_URL || MOCK_PAAS_LOG_URL,
    );
    this.pixelUrl.searchParams.set('configuration', this.advertisementId);
    if (this.subId) this.pixelUrl.searchParams.set('subId', this.subId);
    this.pixelUrl.searchParams.set('da', this.advertisementId);
    this.pixelUrl.searchParams.set('bid', getSessionStorage().bid);
    this.pixelUrl.searchParams.set('referer', window.location.href || '');
    this.pixelUrl.searchParams.set('deviceWidth', window.screen.width || '');
    this.pixelUrl.searchParams.set('deviceHeight', window.screen.height || '');
    this.pixelUrl.searchParams.set(
      'embedWidth',
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      '',
    );

    this.state = {
      /** @type {Boolean} */
      showURLModal: false,
      /** @type {Boolean} */
      showCafeModal: false,
      /** @type {Boolean} */
      showFrame: false,
      /** @type {URL} */
      cafeUrl: null,
      /** @type {Number} */
      amount: null,
      /** @type {Number} */
      term: null,
      /** @type {Boolean} */
      showOverlay: false,
      /** @type {String} */
      overlayMessage: '',
    };
  }

  buildDestinationURLs = (data) => {
    const { cafeContainerUrl, subId, advertisementId } = this.props.affiliateData;
    const imodHostname = 'taurine';
    const taurineResumeParameter = 'resume=';
    const imodResumeParameter = '!resume=';
    const paramAdvertisementID = 'advertisement';
    const paramBackURL = 'backUrl';
    const paramCafeURL = 'cafeUrl';
    const paramSubID = 'subId';
    const resumeURL = data.result.url;

    // if non-imod: add backURL + subID params
    if (resumeURL.search(imodHostname) < 0) {
      const embedURL = new URL(resumeURL);
      embedURL.searchParams.set(paramBackURL, window.location.href);
      embedURL.searchParams.set(paramSubID, subId);
      const containerURL = new URL(cafeContainerUrl);
      containerURL.searchParams.set(paramCafeURL, embedURL.toString());
      containerURL.searchParams.set(paramSubID, subId);
      return { embedURL, containerURL };
    }

    // if imod:
    // cut resume token from taurine url and append to imod url, also add advertisement + subId
    const resumeToken = resumeURL.substring(resumeURL.search(taurineResumeParameter) + 7);
    const embedURL = new URL(resumeURL);
    const redirectURL = new URL(process.env.REACT_APP_IMOD_BASE_URL);
    redirectURL.searchParams.set(paramAdvertisementID, advertisementId);
    redirectURL.searchParams.set(paramSubID, subId);
    redirectURL.searchParams.set('src', 'widget');
    redirectURL.hash = imodResumeParameter + resumeToken;
    return { embedURL, redirectURL };
  };

  isCafe = embedURL => (embedURL).toString().indexOf('//cafe.finanzcheck') >= 0;

  /**
   * Waits for the given promise to resolve and opens CAFE according to configuration either in a modal dialog,
   * in the current window or a new window.
   */
  resolveToUrl = async (promise) => {
    const submitMode = this.props.affiliateData.submitMode.toUpperCase();
    const { buildDestinationURLs, isCafe } = this;
    // open immediately to prevent popup blocker from eating our window
    let popup = null;
    if (submitMode === 'WINDOW') {
      popup = window.open('', '_blank');
    }
    // wait for request
    const data = await promise;
    // abort if result not defined
    if (!data || !data.result) {
      if (popup) popup.close();
      return data;
    }

    const { embedURL, redirectURL } = buildDestinationURLs(data);
    switch (submitMode) {
      default:
      case 'MODAL':
        if (isCafe(embedURL)) {
          // open CAFE in modal dialog - imod not supported for modal mode!
          this.setState({
            showCafeModal: true,
            cafeUrl: embedURL.toString(),
            amount: data.request.amount,
            term: data.request.term,
          });
          break;
        }
        // else: fall through to redirect:
        // eslint-disable-next-line no-fallthrough

      case 'REDIRECT':
        window.location.href = (redirectURL || embedURL).toString();
        break;
      case 'WINDOW':
        // set location of the window opened earlier
        popup.location.href = (redirectURL || embedURL).toString();
        break;
      case 'AMP':
        window.open((redirectURL || embedURL).toString(), '_top');
        break;
    }
    widgetRoot.scrollIntoView(true);
    return data;
  };

  handleModalClose = modalKey => () => this.setState({ [modalKey]: false })

  handleCafeModalClose = () => this.setState({ showCafeModal: false });

  handleFrameClose = () => this.setState({ showFrame: false });

  /**
   * Triggers a loading overlay using the given promise.
   * To prevent flickering on fast requests or cached results,
   * the overlay is only enabled when the promise is taking more than the given timeout to resolve.
   * @param {Promise} promise - promise to trigger loading overlay with
   * @param {Number} timeout - timeout to enable overlay
   * @returns {Promise} returns a promise which always resolves successfully, either to the result or to undefined
   */
  resolveWithLoader = async (promise, timeout = 300) => {
    // display overlay if the promise is taking more than the given timeout to resolve
    const handle = setTimeout(
      () => this.setState({ showOverlay: true }),
      timeout,
    );
    try {
      const result = await promise;
      // skip showing the overlay on fast requests
      clearTimeout(handle);
      // hide overlay if it was enabled
      this.setState({
        showOverlay: false,
        overlayMessage: '',
      });
      // resolve to the result
      return result;
    }
    catch (err) {
      // clear timeout if it has not triggered yet
      clearTimeout(handle);
      // display overlay with error message
      this.setState({
        showOverlay: true,
        overlayMessage: (
          <span>
            Es ist ein Problem aufgetreten.
            <br />
            Bitte versuchen Sie es später erneut.
          </span>
        ),
      });
      // resolve to undefined
      return undefined;
    }
  };

  openURLInModal = (url) => {
    this.setState({ showURLModal: true, showURLModalURL: url });
  }

  render() {
    const {
      state: {
        showURLModal, showURLModalURL, showCafeModal, showFrame, cafeUrl, amount, term,
      },
      props: { children, affiliateData: { submitMode } },
      resolveToUrl,
      resolveWithLoader,
    } = this;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        ...this.props,
        submitUrl: this.submitUrl,
        resolveToUrl,
        resolveWithLoader,
      }));

    return (
      <AMPIdentifier.Provider value={submitMode && submitMode.toUpperCase() === 'AMP'}>
        <StyledApp>
          {!showFrame && (
            <div style={{ position: 'relative' }}>
              <ShowModalContext.Provider value={{ show: this.openURLInModal }}>
                {childrenWithProps}
              </ShowModalContext.Provider>
              {this.state.showOverlay && (
                <LoadingOverlay>
                  {this.state.overlayMessage
                    ? (
                      this.state.overlayMessage
                    )
                    : (
                      <LoadingSpinner />
                    )}
                </LoadingOverlay>
              )}
            </div>
          )}

          {showFrame && (
            <div>
              <CafeHeader amount={amount || 0} term={term || 0} />
              <CafeFrame src={cafeUrl} onNavigateBack={this.handleFrameClose} />
            </div>
          )}

          <ModalStack>
            {showURLModal && (
              <StyledApp>
                <Modal
                  onRequestClose={this.handleModalClose('showURLModal')}
                  showCloseButton
                  allowCloseOnBackdrop={false}
                >

                  <URLFrame url={showURLModalURL} />

                </Modal>
              </StyledApp>
            )}
          </ModalStack>

          <ModalStack>
            {/* TODO: find out how to add Styles in a better way */}
            {showCafeModal && (
              <StyledApp>
                <Modal
                  onRequestClose={this.handleModalClose('showCafeModal')}
                  showCloseButton
                  allowCloseOnBackdrop={false}
                >
                  <CafeHeader amount={amount || 0} term={term || 0} />
                  <CafeFrame
                    src={cafeUrl}
                    onNavigateBack={this.handleModalClose('showCafeModal')}
                  />
                </Modal>
              </StyledApp>
            )}
          </ModalStack>

          {this.advertisementId && <PaasPixel url={this.pixelUrl} />}
        </StyledApp>
      </AMPIdentifier.Provider>
    );
  }

}

export default deepDefaultProps({
  affiliateData: {
    advertisementId: process.env.REACT_APP_PAAS_ADVERTISEMENT_ID,
    cafeContainerUrl: process.env.REACT_APP_CAFE_CONTAINER_URL,
    submitMode: '',
  },
})(App);
