import styled from 'styled-components';

/**
 * @namespace
 * @class CheckboxFieldTextWrapper
 * @param {Object} props                       - The component properties.
 * @classdesc A (styled) `div` component.
 */
export const CheckboxFieldTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
  
  *:first-child{
  color: ${props => props.theme.labelColorChecked};
  }
  
  *:last-child{
  color: ${props => props.theme.labelColor};
  }
`;

CheckboxFieldTextWrapper.propTypes = {
};
