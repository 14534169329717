/**
 * This function compares two offers of the same bank if the purpose is a vehicle.
 *
 * @param {Object} a - The first offer to compare.
 * @param {Object} b - The second offer to compare.
 * @returns {number} - The result of the comparison.
 */
function compareVehiclePurpose(a, b) {
  if (a.productType === 'CAR_LOAN' && b.productType !== 'CAR_LOAN') {
    return -1;
  }
  if (a.productType !== 'CAR_LOAN' && b.productType === 'CAR_LOAN') {
    return 1;
  }
  if (
    a.productType === 'INSTALLMENT_LOAN' &&
    b.productType !== 'INSTALLMENT_LOAN'
  ) {
    return -1;
  }
  if (
    a.productType !== 'INSTALLMENT_LOAN' &&
    b.productType === 'INSTALLMENT_LOAN'
  ) {
    return 1;
  }
  return a.id < b.id ? -1 : 1;
}

/**
 * This function compares two offers of the same bank if the purpose is not a vehicle.
 *
 * @param {Object} a - The first offer to compare.
 * @param {Object} b - The second offer to compare.
 * @returns {number} - The result of the comparison.
 */
function compareDefaultPurpose(a, b) {
  if (a.effectiveInterestRate < b.effectiveInterestRate) {
    return -1;
  }
  if (a.effectiveInterestRate > b.effectiveInterestRate) {
    return 1;
  }
  if (
    a.productType === 'INSTALLMENT_LOAN' &&
    b.productType !== 'INSTALLMENT_LOAN'
  ) {
    return -1;
  }
  if (
    a.productType !== 'INSTALLMENT_LOAN' &&
    b.productType === 'INSTALLMENT_LOAN'
  ) {
    return 1;
  }
  return a.id < b.id ? -1 : 1;
}

/**
 * This function compares two offers of different banks.
 *
 * @param {Object} a - The first offer to compare.
 * @param {Object} b - The second offer to compare.
 * @returns {number} - The result of the comparison.
 */
function compareBanks(a, b) {
  if (a.effectiveInterestRate > b.effectiveInterestRate) {
    return 1;
  }

  if (a.effectiveInterestRate < b.effectiveInterestRate) {
    return -1;
  }

  if (a.productOrder > b.productOrder) {
    return 1;
  }

  if (a.productOrder < b.productOrder) {
    return -1;
  }

  return 0;
}

/**
 * Reducer to filter offers based on the CreditForm settings.
 */
export default ({ purpose, offers }) => {
  const validOffersByBank = {};

  offers.forEach((offer) => {
    const { conditions } = offer;

    if (conditions.length > 0) {
      const calculatedOffer = { ...offer };
      delete calculatedOffer.conditions;
      delete calculatedOffer.purposes;

      if (!(offer.bank.code in validOffersByBank)) {
        validOffersByBank[offer.bank.code] = [];
      }
      validOffersByBank[offer.bank.code].push({
        ...calculatedOffer,
        productId: calculatedOffer.id,
        ...conditions[0],
      });
    }
  });

  Object.keys(validOffersByBank).forEach((key) => {
    if (purpose === 'NEW_CAR' || purpose === 'PRE_OWNED_CAR') {
      validOffersByBank[key].sort(compareVehiclePurpose);
    }
    else {
      validOffersByBank[key].sort(compareDefaultPurpose);
    }
  });
  return Object.keys(validOffersByBank)
    .map(key => validOffersByBank[key][0])
    .sort(compareBanks);
};
