import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Siegel from '../../assets/images/siegel.svg';
import Button from '../../atoms/Button';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';
import AmountField from '../../molecules/AmountField/AmountField';
import CheckboxField from '../../molecules/CheckboxField/CheckboxField';
import { CheckboxFieldDescription } from '../../molecules/CheckboxField/checkboxFieldDescription';
import EmailField from '../../molecules/EmailField/EmailField';
import PhoneField from '../../molecules/PhoneField/PhoneField';
import SelectField from '../../molecules/SelectField';
import TabField, { DIRECTION_HORIZONTAL } from '../../molecules/TabField/TabField';
import TextField from '../../molecules/TextField';
import buildTwoThirdLegalTextString from '../../util/buildTwoThirdLegalTextString';
import { calculateMonthlyPayment, calculateTotal } from '../../util/calculateRates';
import deepDefaultProps from '../../util/deepDefaultProps';
import {
  buildRequestData,
  getOfferByBestRate,
  purposeOptions,
  relationOptions,
  salutationOptions,
  termOptions,
} from '../../util/formHelper';
import { MOCK_CAFE_INFO_OBLIGATION_URL } from '../../util/mockData';
import { formatAmount } from '../../util/numberFormatter';
import {
  BestOffer,
  ContactFieldset,
  CreditFieldset,
  Footer,
  Form,
  LegalText,
  Row,
  SealBottom,
  SecondDebtorFieldset,
  Section,
  TermsWrapper,
} from './LeadForm.style';


/**
 * Prefix for all keys within the WebStorage
 */
export const STORAGE_KEY_PREFIX = 'fc-lead-';
export const STORAGE_KEY_RESUME_HASH = STORAGE_KEY_PREFIX + 'resumeHash';

class LeadForm extends PureComponent {

  static propTypes = {
    minAmount: PropTypes.number,
    maxAmount: PropTypes.number,
    affiliateData: PropTypes.object.isRequired,
    submitUrl: PropTypes.instanceOf(URL).isRequired,
    resolveToUrl: PropTypes.func.isRequired,
    resolveWithLoader: PropTypes.func.isRequired,
  };

  static defaultProps = {
    minAmount: 1000,
    maxAmount: 100000,
  };

  /**
   * @constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    const {
      affiliateData: {
        advertisementId,
        amount,
        term,
        purpose,
        salutation,
        firstName,
        lastName,
        phone,
        email,
        salutation2,
        firstName2,
        lastName2,
        relation,
      },
    } = this.props;

    /** @type {URL} */
    this.infoObligationUrl = new URL(
      process.env.REACT_APP_CAFE_INFOOBLIGATION_URL ||
      MOCK_CAFE_INFO_OBLIGATION_URL,
    );

    this.consentDeclarationUrl = new URL(process.env.REACT_APP_SCUF_CONSENT_DECLARTION_URL);
    this.agbUrl = new URL(process.env.REACT_APP_SCUF_AGB_URL);
    this.infoObligationUrl.searchParams.set('configuration', advertisementId);

    /** @type {HTMLFormElement} */
    this.formElement = React.createRef();

    this.state = {
      amount,
      term,
      purpose,
      salutation,
      firstName,
      lastName,
      phone,
      email,
      secondary: Boolean(firstName2 || lastName2),
      salutation2,
      firstName2,
      lastName2,
      relation,
      bestOffer: {},
      showError: false, // TODO rename to hasError since this form may HAVE an error
    };

    this.updateBestOffer({ amount, term, purpose });
  }

  /**
   * @param {Object} nextProps
   * @param {Object} nextState
   */
  componentWillUpdate = (nextProps, nextState) => {
    const { amount, term, purpose } = nextState;

    if (
      this.state.amount !== amount
      || this.state.term !== term
      || this.state.purpose !== purpose
    ) {
      debounce(() => { this.updateBestOffer({ amount, term, purpose }); }, 500);
    }
  };

  /**
   * Will update the best offer display
   *
   * @param {{ amount: Number, term: Number, purpose: String }}
   */
  updateBestOffer = async ({ amount, term, purpose }) => {
    const {
      affiliateData: {
        advertisementId,
      },
      resolveWithLoader,
    } = this.props;

    const bestOffer = await resolveWithLoader(getOfferByBestRate({ amount, term, purpose }, advertisementId));
    if (!bestOffer) return;

    const effectiveInterestTwoThird = bestOffer.effectiveInterestRateTwoThird / 100;
    const effectiveInterest = bestOffer.effectiveInterestRate / 100;

    // --- extend offer data ---
    bestOffer.monthlyRate = calculateMonthlyPayment({
      amount,
      term,
      effectiveInterest,
    });

    bestOffer.monthlyRateTwoThird = calculateMonthlyPayment({
      amount,
      term,
      effectiveInterest: effectiveInterestTwoThird,
    });

    // TODO check logic ... calculateTotal is described as always returning a Number!
    bestOffer.totalTwoThird = Number(calculateTotal({
      amount,
      term,
      effectiveInterest: effectiveInterestTwoThird,
    }).toFixed(2));
    // --- /extend offer data ---

    this.setState({
      bestOffer,
    });
  };

  /**
   * Handles change event on amount input
   * Verifies new value and saves it into state
   *
   * @param {Number} value
   */
  handleAmountChange = (event, value) => {
    const { maxAmount, minAmount } = this.props;

    if (value >= minAmount && value <= maxAmount) {
      this.setState({
        amount: value,
      });
    }
  };

  /**
   * Handles change event on term input
   * Verifies new value and saves it into state
   *
   * @param {Number} value
   */
  handleTermChange = (event, value) => {
    const options = termOptions.filter(item => item.value === value);

    if (options.length) {
      this.setState({
        term: value,
      });
    }
  };

  /**
   * Handles change event on purpose input
   * Verifies new value and saves it into state
   *
   * @param {Number} value
   */
  handlePurposeChange = (event, value) => {
    const options = purposeOptions.filter(item => item.value === value);

    if (options.length) {
      this.setState({
        purpose: value,
      });
    }
  };

  /**
   * Handles change event on second deptor input
   *
   * @param {Event} event
   */
  handleSecondary = (event) => {
    this.setState({
      secondary: event.target.checked,
    });
  };

  /**
   * TODO rename to "handleChange"
   * Handles change event on form
   * Checks validity and updates state
   */
  handleValidity = () => {
    if (this.state.showError && this.formElement.current.checkValidity()) {
      this.setState({
        showError: false,
      });
    }
  };

  /**
   * Handles invalid event on form
   */
  handleInvalid = () => {
    this.setState({
      showError: ' Bitte überprüfen Sie ihre Eingaben!',
    });
  };

  /**
   * Handles submit event on form
   *
   * @param {Event} event
   */
  handleSubmit = (event) => {
    event.preventDefault();

    const form = event.target;

    if (!form.checkValidity()) return;

    const formData = new FormData(form);
    const resumeHash = sessionStorage.getItem(STORAGE_KEY_RESUME_HASH);

    if (resumeHash) {
      formData.append('resumeHash', resumeHash);
    }

    const requestData = buildRequestData(formData, this.props.affiliateData, true);
    const request = new Request(this.formElement.current.action, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
      }),
      body: JSON.stringify(requestData),
    });

    const promise = fetch(request)
      .then((response) => {
        if (!response.ok) {
          this.setState({ showError: 'Der Server meldete einen Fehler. Bitte überprüfen Sie Ihre Daten noch einmal!' });
          throw new Error(`Server responded with status ${response.status} - ${response.statusText}`);
        }

        if (!response.headers.get('Content-Type').match(/^application\/json\b/)) {
          throw new Error('Bad content type: ' + response.headers.get('Content-Type'));
        }

        return response.json()
          .then((data) => {
            sessionStorage.setItem(STORAGE_KEY_RESUME_HASH, data.result.resumeHash);
            data.request = requestData; // extend data object for callback
            this.setState({ showError: false });
            return data;
          });
      })
      .catch((reason) => {
        if (!this.state.showError) {
          this.setState({ showError: 'Beim Übertragen der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!' });
        }
        console.error('API request failed: ' + reason);
      });

    // resolve promise with loading indicator and open the resulting cafe url
    const { resolveToUrl, resolveWithLoader } = this.props;
    resolveToUrl(resolveWithLoader(promise));
  };

  /**
   * @returns {JSX}
   */
  render() {
    const {
      affiliateData: {
        buttonText,
      },
      minAmount,
      maxAmount,
    } = this.props;
    const {
      amount,
      term,
      purpose,
      salutation,
      firstName,
      lastName,
      phone,
      email,
      secondary,
      salutation2,
      firstName2,
      lastName2,
      relation,
      bestOffer,
      showError,
    } = this.state;

    const monthlyRateElementId = `_${Math.random().toString(36).substr(2, 10)}`; // TODO use UI-Kit generator
    const effectiveInterestElementId = `_${Math.random().toString(36).substr(2, 10)}`; // TODO use UI-Kit generator

    return (
      <Form
        ref={this.formElement}
        action={this.props.submitUrl}
        method="post"
        onChange={this.handleValidity}
        onInvalid={this.handleInvalid}
        onSubmit={this.handleSubmit}
      >
        <CreditFieldset>
          <Section>
            <div>Ihr Kreditwunsch</div>
            <Siegel />
          </Section>
          <Row>
            <AmountField
              name="amount"
              label="Nettodarlehensbetrag"
              onChange={this.handleAmountChange}
              onBlur={this.handleAmountChange}
              placeholder="10.000"
              value={amount}
              min={minAmount}
              max={maxAmount}
              unit="Euro"
              required
              autoComplete="off"
            />
            <SelectField
              name="term"
              label="Laufzeit"
              onChange={this.handleTermChange}
              value={term.toString()}
              options={termOptions}
            />
          </Row>
          <Row>
            <SelectField
              name="purpose"
              label="Verwendungszweck"
              onChange={this.handlePurposeChange}
              value={purpose}
              options={purposeOptions}
              required
            />
            <BestOffer>
              <div>
                <output id={monthlyRateElementId}>{formatAmount(bestOffer.monthlyRate, '€', 2)}</output>
                <label htmlFor={monthlyRateElementId}>Monatliche Rate</label>
              </div>
              <div>
                <output
                  id={effectiveInterestElementId}
                >ab {formatAmount(bestOffer.effectiveInterestRate, '%', 2)}
                </output>
                <label htmlFor={effectiveInterestElementId}>Effektiver Jahreszins*</label>
              </div>
            </BestOffer>
          </Row>
        </CreditFieldset>

        <ContactFieldset>
          <Section>Ihre Kontaktdaten</Section>
          <Row>
            <TabField
              name="salutation"
              direction={DIRECTION_HORIZONTAL}
              options={salutationOptions}
              required
              value={salutation}
            />
            <div />
          </Row>
          <Row>
            <TextField
              name="firstName"
              label="Vorname"
              placeholder="Vorname"
              required
              autoComplete="given-name"
              value={firstName}
            />
            <TextField
              name="lastName"
              label="Nachname"
              placeholder="Nachname"
              required
              autoComplete="family-name"
              value={lastName}
            />
          </Row>
          <Row>
            <PhoneField
              name="phone"
              label="Telefonnummer tagsüber"
              placeholder="0170 12345678"
              required
              autoComplete="tel"
              value={phone}
            />
            <EmailField
              name="email"
              label="E-Mail-Adresse"
              placeholder="mustermann@finanzcheck.de"
              required
              autoComplete="email"
              value={email}
            />
          </Row>
          <CheckboxField
            box
            name="secondary"
            onChange={this.handleSecondary}
            label="Zweiten Kreditnehmer hinzufügen"
            description="Ein zweiter Kreditnehmer erhöht die Wahrscheinlichkeit auf einen günstigen Zinssatz."
            value="secondary"
            checked={secondary}
            // TODO: When UI-Kit #80 is published (planned for v4.0.2), this attribute can safely get removed
            id="ah18ga80aw"
          />
          {secondary && (
            <SecondDebtorFieldset>
              <Section>2. Kreditnehmer</Section>
              <Row>
                <TabField
                  name="salutation2"
                  direction={DIRECTION_HORIZONTAL}
                  options={salutationOptions}
                  required
                  value={salutation2}
                />
                <div />
              </Row>
              <Row>
                <TextField
                  name="firstName2"
                  label="Vorname"
                  placeholder="Vorname"
                  required
                  autoComplete="given-name"
                  value={firstName2}
                />
                <TextField
                  name="lastName2"
                  label="Nachname"
                  placeholder="Nachname"
                  required
                  autoComplete="family-name"
                  value={lastName2}
                />
              </Row>
              <TabField
                name="relation"
                direction={DIRECTION_HORIZONTAL}
                options={relationOptions}
                required
                value={relation}
              />
            </SecondDebtorFieldset>
          )}
        </ContactFieldset>
        <CheckboxFieldDescription>
          <div style={{ margin: '0.5em' }}>
            <br />
            <b>
              Mit Betätigen des &quot;Weiter&quot;-Buttons erteile ich FINANZCHECK einen
              kostenlosen Kreditvergleichs- und Vermittlungsauftrag. Hierfür
              gelten die{' '}
              <a target="_blank" rel="noopener noreferrer" href={this.agbUrl}>
                AGB
              </a>{' '}
              .
            </b>{' '}
            Den Erhalt der{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.infoObligationUrl}
            >
              Pflichtinformationen
            </a>{' '}
            bestätige ich.
          </div>
        </CheckboxFieldDescription>
        <TermsWrapper>
          <CheckboxField
            name="dataProcessingConsent"
            label={`Ich stimme der Verarbeitung meiner Daten entsprechend der
              <a target="_blank" href="${this.consentDeclarationUrl
              }">Einwilligungserklärung</a> von FINANZCHECK zu.
              Diese Einwilligung kann ich jederzeit mit Wirkung für die Zukunft widerrufen.`}
          />
        </TermsWrapper>
        <Footer>
          {/* TODO remove when dialogs are implemented */}
          {showError && (
            <ErrorMessage>
              <p>
                <strong>Achtung:</strong>
                {showError}
              </p>
            </ErrorMessage>
          )}

          <Row>
            <SealBottom>
              <Siegel />
            </SealBottom>
            <Button type="submit" label={buttonText} />
          </Row>

          <LegalText>
            {buildTwoThirdLegalTextString({
              netAmountTwoThird: amount,
              totalAmountTwoThird: bestOffer.totalTwoThird,
              nominalInterestRateTwoThird: bestOffer.nominalInterestRateTwoThird,
              effectiveInterestRateTwoThird: bestOffer.effectiveInterestRateTwoThird,
              monthlyRateTwoThird: bestOffer.monthlyRateTwoThird,
              bankName: bestOffer.bank ? bestOffer.bank.name : null,
              term,
            })}
          </LegalText>
        </Footer>
      </Form>
    );
  }

}

export default deepDefaultProps({
  affiliateData: {
    buttonText: 'Weiter',
    amount: 10000,
    term: 84,
    purpose: 'OTHER',
    salutation: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    salutation2: '',
    firstName2: '',
    lastName2: '',
    relation: '',
  },
})(LeadForm);
