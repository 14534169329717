import React from 'react';
import styled from 'styled-components';
import { applyStyleModifiers, styleModifierPropTypes } from 'styled-components-modifiers';

/**
 * Component modifier configuration for different state styles.
 * @returns {JSX}
 * @param {Object} props
 */

const PlainCheckbox = props => (
  <label {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line max-len
        d="M9,16.17 L5.18230629,12.3523063 C4.98753185,12.1575319 4.67191249,12.1569742 4.47645102,12.3510592 L3.76605641,13.056451 C3.57010551,13.251022 3.56898685,13.5676025 3.76355781,13.7635534 C3.76397277,13.7639713 3.76438847,13.7643885 3.7648049,13.7648049 L8.64644661,18.6464466 C8.84170876,18.8417088 9.15829124,18.8417088 9.35355339,18.6464466 L20.6464466,7.35355339 C20.8417088,7.15829124 20.8417088,6.84170876 20.6464466,6.64644661 L19.9433863,5.94372044 C19.7481894,5.74852351 19.4317357,5.74844877 19.2364466,5.94355347 L9,16.17 Z"
        id="path-1"
      />
    </svg>
  </label>
);

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_CHECKBOXFIELD_CHECKBOX = {
  isInValidAndHasFocus: ({ theme }) => `
    box-shadow: ${theme.shadowInvalid};`,
  isInvalid: ({ theme }) => `
    border-color: ${theme.borderColorInvalid};
    &:hover {
      border-color: ${theme.borderColorInvalid};
    }`,
  isChecked: ({ theme }) => `
    background-color: ${theme.backgroundColorChecked};
    border-color: ${theme.borderColorChecked};

    & svg {
      visibility: visible !important;
      transform: scale3d(1, 1, 1);
    }`,
  hasFocus: ({ theme }) => `
    border-color: ${theme.borderColorFocus};
    box-shadow: ${theme.shadowFocus}`,
};

/**
 * @namespace
 * @class CheckboxFieldCheckbox
 * @param {Object} props                       - The component properties.
 * @classdesc A (custom styled) `PlainCheckbox` component.
 */
export const CheckboxFieldCheckbox = styled(PlainCheckbox)`
  all: initial;
  display: inline-block;
  min-width: 1.25em;
  min-height: 1.25em;
  max-width: 1.25em;
  max-height: 1.25em;
  margin-right:0.5em;
  color: ${props => props.theme.checkColor};
  background-color: ${props => props.theme.backgroundColor};
  border: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
  border-radius: .125em;
  box-shadow: ${props => props.theme.shadow};
  transition: box-shadow ${props => props.theme.animationDuration} ease-out;
  cursor: pointer;
  user-select: none;

  &:hover {
    border-color: ${props => props.theme.borderColorHover};
  }

  & svg {
    visibility: hidden;
    color: ${props => props.theme.checkColor};
    width: 100%;
    height: 100%;
    vertical-align: top;
    transform: scale3d(.5, .5, 1);
    transition: transform ${props => props.theme.animationDuration} cubic-bezier(.5, 2, .5, 1);
  }

  ${applyStyleModifiers(MODIFIER_CHECKBOXFIELD_CHECKBOX)}
`;

CheckboxFieldCheckbox.propTypes = {
  /**
   * Modifiers
   */
  modifiers: styleModifierPropTypes(MODIFIER_CHECKBOXFIELD_CHECKBOX),
};
