import React from 'react';

import { TextInput } from './textInput';
import { TextFieldBorder } from './textFieldBorder';
import { TextFieldFrame } from './textFieldFrame';
import { TextFieldUnit } from './textFieldUnit';
import { TextFieldLabel } from './textFieldLabel';
import { TextFieldHint } from './textFieldHint';
import { TextFieldIconValid } from './textFieldIconValid';
import { TextFieldIconInvalidButton } from './textFieldIconInvalidButton';
import { TextFieldIconClearButton } from './textFieldIconClearButton';
import { TextFieldError } from './textFieldError';
import { TextFieldWrapper } from './textFieldWrapper';
import { TextFieldDefaultProps, TextFieldPropTypes } from '../PropTypes';

class TextField extends React.PureComponent {

  static propTypes = TextFieldPropTypes;

  static defaultProps = TextFieldDefaultProps;

  render() {
    const {
      id,
      label,
      unit,
      hint,
      value,
      isRequired,
      isDisabled,
      isValid,
      hasFocus,
      error,
      inputRef,
      onBlur,
      onChange,
      onFocus,
      onInput,
      onInvalid,
      onKeyDown,
      clearInput,
    } = this.props;

    return (
      <TextFieldWrapper {...{ isValid }}>
        <TextFieldFrame>
          <TextInput
            {...this.props}
            ref={inputRef}
            id={id}
            value={value}
            required={isRequired}
            disabled={isDisabled}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onInput={onInput}
            onChange={onChange}
            onBlur={onBlur}
            onInvalid={onInvalid}
          />

          {unit && (
            <TextFieldUnit
              modifiers={[
                ...(hasFocus ? ['hasFocus'] : []),
                ...(isValid === false ? ['isInvalid'] : []),
                ...(isValid === true ? ['isValid'] : []),
              ]}
            >
              {unit}
            </TextFieldUnit>
          )}

          <TextFieldIconValid {...{ hasFocus, isValid }} />

          <TextFieldIconInvalidButton
            modifiers={[
              ...(!hasFocus && isValid === false ? ['hasFocus'] : []),
              ...(isValid === false ? ['isInvalid'] : []),
            ]}
          />

          <TextFieldIconClearButton
            onClick={clearInput}
            disabled={isDisabled}
            modifiers={[
              ...(hasFocus ? ['hasFocus'] : []),
              ...(isValid === false ? ['isInvalid'] : []),
            ]}
          />

          <TextFieldLabel
            modifiers={[
              ...(hasFocus ? ['hasFocus'] : []),
              ...(value ? ['hasValue'] : []),
              ...(isValid === false ? ['isInvalid'] : []),
            ]}
            htmlFor={id}
          >
            {label}
          </TextFieldLabel>

          <TextFieldBorder
            modifiers={[
              ...(hasFocus ? ['hasFocus'] : []),
              ...(isValid === true ? ['isValid'] : []),
              ...(isValid === false ? ['isInvalid'] : []),
              ...(isValid === false && hasFocus ? ['isInValidAndHasFocus'] : []),
              ...(isValid === true && hasFocus ? ['isValidAndHasFocus'] : []),
            ]}
          />
        </TextFieldFrame>

        {error && <TextFieldError>{error}</TextFieldError>}

        {hint && (
          <TextFieldHint modifiers={[...(isValid === false ? ['isInvalid'] : [])]}>
            {hint}
          </TextFieldHint>
        )}

      </TextFieldWrapper>
    );
  }

}

export default TextField;
