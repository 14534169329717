/**
 * There are multiple approaches to get rid of the iOS body scroll problem
 *
 * What to take care about:
 * - IFrame must get resized by the host page to fit its content height. So there is no scrolling available
 * - Modal's ScrollBox must set `-webkit-overflow-scrolling: touch;` to allow scrolling in iOS Safari
 * - When scroll position of the ScrollBox is at min/max, `touchmove` events need to be cancelled,
 *   to prevent the page in background to scroll
 * - Touch-Events won't be accessible in when hitting the Iframe (at least iOS Safari). So the background will
 *   scroll again, if the ScrollBox has no scrollHeight. -> TODO make page background unscrollable
 * - It seems like CAFE within Iframe interferes with some events (maybe cancels them or stops propagation), because
 *   it behaves different than other webpages and causes the ScrollBox to be not scrollable.
 *   This can be fixed if you force the ScrollBox to be initially scrollable by adding some tall content.
 * - iOS Safari tries always to to move inputs into the viewport center when they get focused. Because of a bug
 *   with fixed HTMLElements it calculates wrong positions and may scroll your content randomly.
 * - be aware of inconsistencies in scroll behaviour between simulator and real hardware, as well as between iOS versions!!!
 * - IFrame causes unexpected scroll behavior when focusin event is triggered on a select input
 */

import PropTypes from 'prop-types';
import React from 'react';
import detectMobileOS from '../../util/detectOs';
import { Backdrop, CloseButton, Content, Layer } from './Modal.style';

// TODO: find out how to import from file
// import CloseIcon from '../../assets/icons/close.svg';
export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
    />
  </svg>
);

const KEY = {
  ESC: 27,
};

class Modal extends React.PureComponent {

  static propTypes = {
    addPadding: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    showCloseButton: PropTypes.bool,
    allowCloseOnBackdrop: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  static defaultProps = {
    showCloseButton: true,
    allowCloseOnBackdrop: true,
    addPadding: true,
  };

  constructor(props) {
    super(props);

    /**
     * @type {String}
     */
    this.navigatorOS = detectMobileOS(navigator.userAgent);
    /**
     * @type {HTMLStyleElement}
     */
    this.styleElement = null;
    /**
     * Scroll position before entering modal mode
     * @type {number}
     */
    this.windowScrollY = 0;
    /**
     * Width of window scrollbar before entering modal mode.
     * Will be "0" when there was none.
     * @type {number}
     */
    this.windowScrollBarGap = 0;
    /**
     * Original Styles of HtmlElement before entering modal mode.
     * @type {Object}
     */
    this.htmlStyle = null;
    /**
     * Original Styles of BodyElement before entering modal mode.
     * @type {Object}
     */
    this.bodyStyle = null;
    /**
     * @type {HTMLElement}
     */
    this.layerElement = React.createRef();
    /**
     * Scroll position within modal (mode before leaving modal)
     * @type {number}
     */
    this.layerScrollY = 0;
    /**
     * ComputedStyle of layer
     * @type {Object}
     */
    this.layerStyle = null;
  }

  /**
   *
   */
  componentWillMount() {
    const htmlStyle = window.getComputedStyle(document.documentElement);
    const bodyStyle = window.getComputedStyle(document.body);

    this.bodyStyle = {
      marginTop: parseInt(bodyStyle['margin-top'], 10),
      paddingRight: parseInt(bodyStyle['padding-right'], 10),
      width: parseInt(bodyStyle.width, 10),
    };
    this.htmlStyle = {
      marginTop: parseInt(htmlStyle['margin-top'], 10),
      marginRight: parseInt(htmlStyle['margin-right'], 10),
      marginBottom: parseInt(htmlStyle['margin-bottom'], 10),
      paddingTop: parseInt(htmlStyle['padding-top'], 10),
      paddingRight: parseInt(htmlStyle['padding-right'], 10),
      paddingBottom: parseInt(htmlStyle['padding-bottom'], 10),
    };
    this.windowScrollBarGap = this.constructor.getWindowScrollBarGap();
    this.windowScrollY = window.pageYOffset;

    // To be not intrusive use a StyleSheet for manipulating the body.
    // We do not want to deal with checking all styles of the body and revert them later.
    this.styleElement = document.createElement('style');
    document.head.appendChild(this.styleElement);
  }

  componentDidMount() {
    const layerStyle = window.getComputedStyle(this.layerElement.current);
    this.layerStyle = {
      marginTop: parseInt(layerStyle['margin-top'], 10),
      marginBottom: parseInt(layerStyle['margin-bottom'], 10),
    };
    window.scrollTo(window.pageXOffset, 0);

    window.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    this.layerScrollY = window.pageYOffset;
    this.styleElement.parentNode.removeChild(this.styleElement); // if IE11 is EOL use `this.styleElement.remove()`
    this.styleElement = null;
    this.layerStyle = null;
    this.bodyStyle = null;
    this.htmlStyle = null;

    window.scrollTo(window.pageXOffset, this.windowScrollY + this.layerScrollY);
    window.removeEventListener('keyup', this.handleKeyUp);

    window.removeEventListener(
      'resize',
      this.hideEverythingElseAndUpdatePosition,
    );
  }

  /**
   * Returns the current scrollbar gap.
   * Will be "0" when there is none.
   *
   * @returns {number}
   */
  static getWindowScrollBarGap() {
    return window.innerWidth - document.documentElement.clientWidth;
  }

  /**
   * Returns the cascaded offset of all parent elements relative to :root@0:0
   *
   * @param {HTMLElement} element
   * @returns {{x: number, y: number}}
   */
  static getElementOffset(element) {
    let offsetParent = element;
    let offsetX = 0;
    let offsetY = 0;

    while (offsetParent.offsetParent) {
      // eslint-disable-next-line
      offsetParent = offsetParent.offsetParent;

      // Break here because of different box model in browsers
      if (offsetParent === document.body) break;

      offsetX += offsetParent.offsetLeft;
      offsetY += offsetParent.offsetTop;
      offsetX += offsetParent.clientLeft;
      offsetY += offsetParent.clientTop;
    }

    // due to special behaviour of BODY & HTML
    while (offsetParent) {
      if (offsetParent === document.body) {
        const bodyStyle = window.getComputedStyle(document.body);

        // Moz vs. Webkit
        // - Moz: negative offset
        // - Webkit: positive client, but already included in client of children
        offsetX -= offsetParent.offsetLeft;
        offsetY -= offsetParent.offsetTop;

        if (bodyStyle.position !== 'static') {
          offsetX += parseInt(bodyStyle['margin-left'], 10);
          offsetY += parseInt(bodyStyle['margin-top'], 10);
        }
      }
      else if (offsetParent === document.documentElement) {
        const documentStyle = window.getComputedStyle(document.documentElement);

        if (window.getComputedStyle(document.body).position !== 'static') {
          offsetX += parseInt(documentStyle['padding-left'], 10);
          offsetY += parseInt(documentStyle['padding-top'], 10);
          offsetX += Math.abs(offsetParent.clientLeft);
          offsetY += Math.abs(offsetParent.clientTop);
        }
        offsetX += parseInt(documentStyle['margin-left'], 10);
        offsetY += parseInt(documentStyle['margin-top'], 10);
      }

      offsetParent = offsetParent.parentElement;
    }

    return {
      x: offsetX,
      y: offsetY,
    };
  }

  /**
   * Sends close request via external callback
   *
   * @param {Event} event - Native event from HTMLElement
   */
  requestClose = (event) => {
    this.props.onRequestClose(event);
  };

  /**
   * @param {Event} event - Native event from keyboard
   */
  handleKeyUp = (event) => {
    if (event.keyCode === KEY.ESC) {
      this.requestClose(event);
    }
  };

  render() {
    const {
      props: { showCloseButton, allowCloseOnBackdrop },
    } = this;

    return (
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Backdrop
          onClick={allowCloseOnBackdrop ? this.requestClose : undefined}
        />
        {showCloseButton && (
          <CloseButton onClick={this.requestClose} tabindex="-1">
            <CloseIcon />
          </CloseButton>
        )}
        <Layer
          {...this.props}
          ref={this.layerElement}
          style={{ width: '90%', maxWidth: '840px' }}
          className="ffg-widget-modal"
        >
          <Content>{this.props.children}</Content>
        </Layer>
      </div>
    );
  }

}

export default Modal;
