import Styled from 'styled-components';
import { WIDGET_ROOT_TAG } from '../../../Ids';

export default Styled.button`
  margin-bottom: 1em;

#${WIDGET_ROOT_TAG} &{
  padding: 1em 2em;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  color: ${props => props.theme.buttonTextColor};
  text-align: center;
  background-color: ${props => props.theme.buttonBackgroundColor};
  border: 1px solid ${props => props.theme.buttonBorderColor};
  border-radius: 0.125em;
  box-shadow: ${props => props.theme.buttonShadow};
  outline: unset;
  cursor: pointer;
  transition:
    box-shadow ${props => props.theme.buttonAnimationDuration} ease-out,
    background-color ${props => props.theme.buttonAnimationDuration} ease-out,
    transform ${props => props.theme.buttonAnimationDuration} ease-out
  ;

  &:hover {
    background-color: ${props => props.theme.buttonBackgroundColorHover};
    border-color: ${props => props.theme.buttonBorderColorHover};
    box-shadow: ${props => props.theme.buttonShadowHover};
    transform: ${props => props.theme.transformHover};
  }

  &:focus {
    border-color: ${props => props.theme.buttonBorderColorFocus};
    box-shadow: ${props => props.theme.buttonShadow}, ${props =>
  props.theme.buttonShadowFocus};
  }
  &::-moz-focus-inner {
    border: none;
  }

  &:hover:focus {
    box-shadow: ${props => props.theme.buttonShadowHover}, ${props =>
  props.theme.buttonShadowFocus};
  }

  &:active {
    background-color: ${props => props.theme.buttonBackgroundColorActive};
    box-shadow: ${props => props.theme.buttonShadowActive};
    transform: ${props => props.theme.transformHover};
  }

  &:disabled {
    color: #95A1AB;
    background-color: ${props => props.theme.buttonBackgroundColorDisabled};
  }
}

`;
