import PropTypes from 'prop-types';
import React from 'react';
import { formatAmount } from '../../util/numberFormatter';
import TextField from '../TextField';
import {
  cleanValue,
  handleKeyDown,
  updateThousandsSeparators,
} from './amountFieldHelper';

class AmountField extends React.PureComponent {

  static propTypes = {
    ...TextField.PropTypes,
    min: PropTypes.number,
    max: PropTypes.number,
  };

  static defaultProps = {
    ...TextField.defaultProps,
    min: undefined,
    max: undefined,
    unit: '',
    onInput: () => { },
    onChange: () => { },
  };

  updateCustomValidity = (input) => {
    const validityState = input.validity;

    // respect already set errors
    if (validityState.customError) return;

    const { min, max, unit } = this.props;
    let error = '';

    if (input.value.length) {
      const value = cleanValue(input.value);

      if (value > max) {
        error = `Der Wert darf maximal ${formatAmount(max, unit)} sein.`;
      }
      else if (value < min) {
        error = `Der Wert muss mindestens ${formatAmount(min, unit)} sein.`;
      }
    }

    input.setCustomValidity(error);
  };

  handleInput = (event) => {
    const { target } = event;

    updateThousandsSeparators(target);
    this.updateCustomValidity(target);

    this.props.onInput(event, cleanValue(target.value));
  };

  handleChange = (event) => {
    this.props.onChange(event, cleanValue(event.target.value));
  };

  render() {
    // add thousands separator for inital value
    const value = this.props.value ? formatAmount(this.props.value) : null;

    return (
      <TextField
        {...this.props}
        pattern={
          this.props.pattern ? this.props.pattern : '[0-9]([0-9.,]*[0-9])?'
        }
        value={value}
        onKeyDown={handleKeyDown}
        onInput={this.handleInput}
        onChange={this.handleChange}
        onBlur={this.handleChange}
      />
    );
  }

}

export default AmountField;
