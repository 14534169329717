import Styled from 'styled-components';

import { VIEWPORT_LG_MIN, VIEWPORT_XS_MAX } from '../../util/mediaQueries';

export const Iframe = Styled.iframe`
  display: block;
  border: none;
  margin: 0 auto;
  width: 77vw;

  max-width: 100%; /* always limit to modal's max space */
  height: 77vh;
  max-height: 100%;

  /* set a max width on large screens */
  @media ${VIEWPORT_LG_MIN} {
    width: 1200px;
    height: 1200px;
  }

  @media ${VIEWPORT_XS_MAX} {
    width: 100vw;
    height: 100vh;
  }
`;
