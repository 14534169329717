import styled from 'styled-components';

export default styled.div`
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0.5em 0;
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.hintColor};
    pointer-events: none;
`;
