import Styled from 'styled-components';

import { VIEWPORT_LG_MIN, VIEWPORT_XS_MAX } from '../../util/mediaQueries';

export const Iframe = Styled.iframe`
  display: block;
  margin: 0 auto;
  width: 77vw;
  max-width: 100%; /* always limit to modal's max space */
  border: none;

  /* set a max width on large screens */
  @media ${VIEWPORT_LG_MIN} {
    width: 1200px;
  }

  @media ${VIEWPORT_XS_MAX} {
    width: 100vw;
  }
`;

export const Loader = Styled.div`
  display: flex;
  margin: 0 auto 2em;
  height: 16em;
  width: 100%;
  max-width: 33vw;
  max-height: 33vh;
  justify-content: center;
  align-items: center;
`;
