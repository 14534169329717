import Styled, { keyframes } from 'styled-components';

const loaderRotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

export const Loader = Styled.div`
  /* iOS Safari animation issue workaround */
  & > svg {
    animation: ${loaderRotation} 1s linear infinite;
  }
`;
