import styled from 'styled-components';

const Error = styled.label`
  all: initial;
  display: block;
  margin: 8px 0 0;
  font-size: .75em;
  font-family: inherit;
  color: ${props => props.theme.colorInvalid};
  pointer-events: none;
`;

export default Error;
