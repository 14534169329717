/* eslint-disable react/no-unused-state */

import React from 'react';
import PropTypes from 'prop-types';

import { CheckboxFieldWrapper } from './checkboxFieldWrapper';
import { CheckboxFieldCheckbox } from './checkboxFieldCheckbox';
import { CheckboxFieldLabel } from './checkboxFieldLabel';
import { CheckboxFieldDescription } from './checkboxFieldDescription';
import { CheckboxFieldTextWrapper } from './checkboxFieldTextWrapper';
import { CheckboxFieldInput } from './checkboxFieldInput';
import { randomHash } from '../../util/random';

/**
 * Returns the clean value of the checkbox
 * - If unchecked return an empty string to guarantee casting to Boolean works as expected.
 * - If value is not defined, but checkbox is checked, return TRUE to guarantee casting to Boolean works as expected.
 *   This is a similar behavior as in HTML specs where inputs without value provide 'on' as default value.
 *
 * @param {*} input
 */
export const cleanValue = input => (input.checked ? (input.value || true) : '');

class CheckboxField extends React.PureComponent {

  /**
   * @type {Object}
   */
  static propTypes = {
    id: PropTypes.string,
    box: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  };

  /**
   * @type {Object}
   */
  static defaultProps = {
    id: '',
    box: false,
    description: undefined,
    value: '',
    checked: false,
    required: false,
    disabled: false,
    onChange: () => {},
    onBlur: () => {},
  };

  /**
   * @constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    const {
      id,
      checked,
      disabled,
      required,
    } = this.props;

    this.state = {
      isRequired: required,
      isDisabled: disabled,
      isChecked: checked,
      hasFocus: false,
      wasTouched: false,
      isDirty: false,
      isValid: undefined,
    };

    this.id = id || randomHash();
    this.input = null;
    this.willFocus = false;
  }

  /**
   * Handles focus event on input
   *
   * @param {Event} event
   */
  handleFocus = () => {
    this.setState({
      hasFocus: true,
    });

    this.willFocus = false;
  };

  /**
   * Handles change event on input
   *
   * @param {Event} event
   */
  handleChange = (event) => {
    const { target } = event;

    // call external handlers before validation and before saving value to state
    this.props.onChange(event, cleanValue(target));

    let newState = {
      isDirty: true,
      isChecked: target.checked,
    };

    // do not mark errors instantly when input is used first time
    if (this.state.isValid === false || this.state.wasTouched) {
      newState = {
        ...newState,
        isValid: target.validity.valid,
      };
    }

    this.setState(newState);
  };

  /**
   * Handles blur event on input
   *
   * @param {Event} event
   */
  handleBlur = (event) => {
    if (this.willFocus) return;

    const { target } = event;

    // call external handlers before validation and before saving value to state
    this.props.onBlur(event);

    this.setState({
      wasTouched: true,
      hasFocus: false,
      isValid: target.validity.valid,
    });
  };

  /**
   * Handles invalid on input
   * Is triggered when calling 'checkValidity()' via input or form
   *
   * @param {Event} event
   */
  handleInvalid = (event) => {
    event.preventDefault(); // prevent browser's error bubble
    const { target } = event;

    this.setState({
      isValid: target.validity.valid,
    });
  };

  /**
   * @returns {JSX}
   */
  render() {
    const {
      id,
      props: {
        name,
        label,
        description,
        value,
        box,
      },
      state: {
        isChecked,
        isRequired,
        isDisabled,
        hasFocus,
        isValid,
      },
    } = this;

    return (
      <CheckboxFieldWrapper
        box={box}
        modifiers={[
          ...(isValid === false ? ['isInvalid'] : []),
          ...(isChecked ? ['isChecked'] : []),
        ]}
      >
        <CheckboxFieldInput
          id={id}
          type="checkbox"
          name={name}
          value={value}
          checked={isChecked}
          required={isRequired}
          disabled={isDisabled}
          onFocus={this.handleFocus}
          onInput={this.handleInput}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onInvalid={this.handleInvalid}
        />
        <CheckboxFieldCheckbox
          htmlFor={id}
          onMouseDown={() => { this.willFocus = true; }}
          modifiers={[
            ...(isChecked ? ['isChecked'] : []),
            ...(hasFocus ? ['hasFocus'] : []),
            ...(isValid === false ? ['isInvalid'] : []),
            ...(isValid === false && hasFocus ? ['isInValidAndHasFocus'] : []),
          ]}
        />
        <CheckboxFieldTextWrapper>
          <CheckboxFieldLabel
            htmlFor={id}
            onMouseDown={() => { this.willFocus = true; }}
            dangerouslySetInnerHTML={{ __html: label }}
            modifiers={[
              ...(hasFocus ? ['hasFocus'] : []),
              ...(isValid === false ? ['isInvalid'] : []),
            ]}
          />
          {description && <CheckboxFieldDescription htmlFor={id} dangerouslySetInnerHTML={{ __html: description }} />}
        </CheckboxFieldTextWrapper>
      </CheckboxFieldWrapper>
    );
  }

}

export default CheckboxField;
