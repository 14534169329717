import React from 'react';
import styled from 'styled-components';
import { applyStyleModifiers, styleModifierPropTypes } from 'styled-components-modifiers';

const IconClearButtonComponent = props => (
  <button
    {...props}
    type="button"
    title="Löschen"
    tabIndex="-1"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line max-len
        d="M18.6464466,6.05644661 L17.9435534,5.35355339 C17.7482912,5.15829124 17.4317088,5.15829124 17.2364466,5.35355339 L12,10.59 L6.76355339,5.35355339 C6.56829124,5.15829124 6.25170876,5.15829124 6.05644661,5.35355339 L5.35355339,6.05644661 C5.15829124,6.25170876 5.15829124,6.56829124 5.35355339,6.76355339 L10.59,12 L5.35355339,17.2364466 C5.15829124,17.4317088 5.15829124,17.7482912 5.35355339,17.9435534 L6.05644661,18.6464466 C6.25170876,18.8417088 6.56829124,18.8417088 6.76355339,18.6464466 L12,13.41 L17.2364466,18.6464466 C17.4317088,18.8417088 17.7482912,18.8417088 17.9435534,18.6464466 L18.6464466,17.9435534 C18.8417088,17.7482912 18.8417088,17.4317088 18.6464466,17.2364466 L13.41,12 L18.6464466,6.76355339 C18.8417088,6.56829124 18.8417088,6.25170876 18.6464466,6.05644661 Z"
        id="path-1"
      />
    </svg>
  </button>
);

/**
 * Component modifier configuration for different state styles.
 * @type {Object}
 * @constant
 * @private
 */
const MODIFIER_ICON_CLEAR = {
  hasFocus: ({ theme }) => `
    margin-left: calc(${theme.paddingVertical} * -1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
    visibility: visible;
    transition:
      margin ${theme.animationDuration} ease-out,
      transform ${theme.animationDuration} ${theme.animationDuration} ease-out,
      opacity ${theme.animationDuration} ${theme.animationDuration} ease-out,
      visibility ${theme.animationDuration} ${theme.animationDuration} linear
    ;`,
  isInvalid: ({ theme }) => `
    color: ${theme.labelColorInvalid};`,
};

/**
 * @namespace
 * @class IconClearButton
 * @param {Object} props                       - The component properties.
 * @classdesc A custom (styled) `IconClearButtonComponent` component.
 */
export const TextFieldIconClearButton = styled(IconClearButtonComponent)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.theme.iconWidth};
  opacity: 0;
  visibility: hidden; /* shouldn't be clickable while invisible */
  transform: scale3d(.7, .7, 1);
  transition:
    margin ${props => props.theme.animationDuration} ease-out,
    transform ${props => props.theme.animationDuration} ease-out,
    opacity ${props => props.theme.animationDuration} ease-out,
    visibility ${props => props.theme.animationDuration} linear;
  z-index: 1; /* at top of browser's autosuggest */

  padding: 0;
  color: ${props => props.theme.labelColorFocus};
  border: none;
  background: none;
  outline: unset;
  cursor: pointer;
  ${applyStyleModifiers(MODIFIER_ICON_CLEAR)}
`;

TextFieldIconClearButton.propTypes = {
  /**
   * Modifiers
   */
  modifiers: styleModifierPropTypes(MODIFIER_ICON_CLEAR),
};
