import Styled from 'styled-components';

import {
  VIEWPORT_XXS,
  VIEWPORT_XS,
} from '../../util/mediaQueries';


export const Wrapper = Styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1 1;
  justify-content: space-around;
  margin-bottom: .5em;
  padding: .5em;

  & > * {
    padding: 0 .5em;
  }
`;

export const Label = Styled.div`
  font-size: 12px;
  color: #165297; /* TODO: theme labelColor */
  text-align: center;
`;

export const Value = Styled.div`
  font-size: 22px; /* TODO where does this comes from? */
  font-weight: bold; /* TODO where does this comes from? */
  color: #666;
  text-align: center;

  @media ${VIEWPORT_XS} {
    font-size: 18px; /* TODO where does this comes from? */
  }

  @media ${VIEWPORT_XXS} {
    font-size: 15px; /* TODO where does this comes from? */
  }
`;

export default Wrapper;
