import styled from 'styled-components';

export const SelectFieldInput = styled.select`
  all: initial;
  flex-grow: 1;
  flex-shrink: 0;
  
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.color};
  font-size: .875em;
  line-height: 1.25em;
  outline: unset;
  z-index: 1;
  
  padding:0.75em;
`;
