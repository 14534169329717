/**
 * Generate Random hash
 *
 * @param {Number} length
 * @returns {string}
 * TODO this function can cause id collisions. It's not preventing duplicated ids.
 */

const POSSIBLE_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
export function randomHash(length = 5) {
  const textLength = length;
  let text = '';

  for (let i = 0; i < textLength; i += 1) {
    text += POSSIBLE_CHARACTERS.charAt(Math.floor(Math.random() * POSSIBLE_CHARACTERS.length));
  }

  return text;
}
