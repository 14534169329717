import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import { Iframe, Loader } from './CafeFrame.style';

export default class CafeFrame extends React.PureComponent {


  static propTypes = {
    src: PropTypes.string.isRequired,
    onNavigateBack: PropTypes.func.isRequired,
  };


  constructor(props) {
    super(props);
    this.iframeElement = React.createRef();
    this.state = {
      domContentLoaded: false,
      height: '0px',
    };

    // TODO find out how to use babel for this!
    // IE11 CustomEvent Polyfill
    /* eslint-disable */
    (function () {

      if (typeof window.CustomEvent === 'function') return false;

      function CustomEvent(event, params) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      }

      CustomEvent.prototype = window.Event.prototype;

      window.CustomEvent = CustomEvent;
    }());
    /* eslint-enable */
  }

  componentWillMount() {
    window.addEventListener('message', this.handleMessage, false);
  }

  componentDidMount() {
    if (this.iframeElement.current !== null) {
      this.iframeElement.current.setAttribute('allow', 'microphone; camera');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  /**
   *  Receives and handles messages from CAFE-Iframe
   *
   *  @param {Event} message - Message Event
   */
  handleMessage = (message) => {
    const { data } = message;

    if (typeof data === 'string') {
      const dataArr = data.split('|');

      switch (dataArr[0]) {
        case 'hideLoadingIndicator':
          // Hah, this is fired a few hundred milliseconds before the heightChange message
          // and therefor this message is unusable =/
          break;

        case 'backLink':
          this.props.onNavigateBack(message);
          break;

        case 'dialog':
          window.open(dataArr[1], '_blank');
          break;

        // disabled due iFrame should scroll by itself if possible
        // on iOS surrounding DIV will add scrolling features
        case 'heightChange':
          // this.iframe.current.style.height = dataArr[1] + 'px';
          this.setState({
            domContentLoaded: true,
            height: dataArr[1] + 'px',
          });

          this.iframeElement.current.dispatchEvent(new CustomEvent('resize', { bubbles: true }));
          break;

        default:
      }
    }
  };

  render() {
    return (
      <div>
        <Iframe
          ref={this.iframeElement}
          src={this.props.src}
          style={{ height: this.state.height }}
        />
        {!this.state.domContentLoaded && (
          <Loader>
            <LoadingSpinner />
          </Loader>
        )}
      </div>
    );
  }

}
