import { formatAmount, parseAmount } from '../../util/numberFormatter';

/**
 * Will strip thousands separator off the value and convert it to a regular float
 * This is required when reading the field
 * WARNING: This is not safe for international use
 *
 * @param {string} amount - the amout that gets turned into float
 * @returns {Number} - float
 */
export const cleanValue = amount => (
  parseFloat((amount + '').replace(/[^,\d]/g, '').replace(',', '.')) || 0
);

/**
 * Add thousands separator directly to the input's value.
 * Also deals with text selections and cursor positions, to keep them as expected.
 *
 * @param {HTMLInputElement} input - value that will be seperated
 */
export const updateThousandsSeparators = (input) => {
  const { selectionStart, selectionEnd } = input;
  let { value } = input;
  let valueLengthDelta = 0;

  value = value.replace(/^0+/, ''); // strip leading zeros

  if (!value.length) return;

  const newValue = formatAmount(parseAmount(value));
  valueLengthDelta = newValue.length - value.length;

  input.value = newValue;

  if (selectionStart === 0 && selectionEnd === 0) {
    input.setSelectionRange(0, 0);
  }
  else if (valueLengthDelta !== 0) {
    // Workaround for Samsung's soft-keyboard, which prevents moving the cursor
    setTimeout(() => {
      input.setSelectionRange(
        selectionStart + valueLengthDelta,
        selectionEnd + valueLengthDelta,
      );
    });
  }
  else {
    input.setSelectionRange(selectionStart, selectionEnd);
  }
};

/**
 * Prevent typing of non numeric characters
 *
 * @param {Event} event - event of input
 */
export const handleKeyDown = (event) => {
  const { target, keyCode } = event;
  const key = keyCode || 0;
  const { value, selectionStart, selectionEnd } = target;

  // if cursor is behind a thousands separator, move cursor after previous digit
  if (key === 8
    && selectionStart === selectionEnd
    && value[selectionEnd - 1] === '.') {
    target.setSelectionRange(
      selectionStart - 1,
      selectionEnd - 1,
    );
    event.preventDefault();
  }

  // if cursor is before a thousands separator, move cursor before next digit
  else if (key === 46
    && selectionStart === selectionEnd
    && value[selectionEnd] === '.'
  ) {
    target.setSelectionRange(
      selectionStart + 1,
      selectionEnd + 1,
    );
    event.preventDefault();
  }

  // check standard input handling
  else if (!(
    // Allow: backspace, tab, enter, escape, delete, deciaml point, dot
    ([8, 9, 13, 27, 46, 110, 190].indexOf(key) > -1) ||
    // Allow: select all, cut, copy, paste -> (ctrl|cmd) + (A|X|C|V)
    ((event.ctrlKey || event.metaKey) && [65, 88, 86, 67].indexOf(key) > -1) ||
    // Allow: home, end, left, right, down, up
    (key >= 35 && key <= 40) ||
    // Allow numbers, no shift+numbers, numpad numbers
    (!event.shiftKey && key >= 48 && key <= 57) || (key >= 96 && key <= 105)
  )) {
    event.preventDefault();
  }
};
